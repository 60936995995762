import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import DataTable from "../tables/table.jsx";
import Select from "react-select";
import {
  Row,
  Badge,
  Input,
  Button,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
  Spinner,
  CardBody,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import { Cols } from "../SeparatorStyle/SeparatorStyle.js";
import { toast } from "react-toastify";
import { handleSearch } from "../search/searchHook.js";
// import { rightAnimate } from "../../FramerMotion/FramerMotion";
import { getProductsApp } from "../../api/products.js";
import { popOverContent, popOverList } from "../../popOvers/popOver.js";
import TableSkeleton from "../UI/TableSkeleton.jsx";

const Products = ({ status, setSkeletonTab }) => {
  const [productsData, setProductsData] = useState([]);
  const [vendorsData, setVendorsData] = useState([]);
  const [count, setCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [vendorFilterValue, setVendorFilterValue] = useState("");
  const [sortingFilterValue, setSortingFilterValue] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const [taggedWithFilter, setTaggedWithFilter] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const [searchArray, setSearchArray] = useState([]);
  const [typeFilter, setTypeFilter] = useState("");
  const [masterCheckBool, setMasterCheckBool] = useState(false);
  const [search, setSearch] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(false);
  const prodStatus = status ? status : "all";
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "draft", label: "Draft" },
    { value: "archived", label: "Archived" },
  ];
  const sortOptions = [
    { value: "asc-title", label: "Product Title (A-Z)" },
    { value: "desc-title", label: "Product Title (Z-A)" },
    { value: "asc-category", label: "Product Type (A-Z)" },
    {
      value: "desc-category",
      label: "Product Type (Z-A)",
    },
  ];

  const fetchProducts = useCallback(
    async ({
      limit = pageLimit,
      page = pageNumber,
      tabStatus = prodStatus,
      status = statusFilter.length === 0 ? [prodStatus] : statusFilter,
      vendor = vendorFilterValue,
      title = search.trim().length > 2 ? search : "",
    }) => {
      try {
        const { data } = await getProductsApp({
          limit,
          page,
          tabStatus,
          status,
          vendor,
          title,
        });

        const { products, vendors, totalCount } = data;

        setCount(totalCount);
        if (totalCount === 0 && !search && statusFilter.length === 0) {
          setEmptyStatus(true);
        } else {
          setEmptyStatus(false);
        }

        if (products) {
          setSearchArray(products);
          setProductsData(products);
          setLoading(false);
          // setSkeletonTab(false);
          setFilterLoading(false);
          setPaginationLoading(false);
          setMasterCheckBool(false);
          setSelectedList([]);
        }
      } catch (err) {
        toast.error("Products data loading error! ");
      }
    },
    [statusFilter, searchTrigger, vendorFilterValue]
  );

  useEffect(() => {
    setLoading(true);
    // setSkeletonTab(true);
  }, []);

  useEffect(() => {
    fetchProducts({});
  }, [fetchProducts]);

  const totalPages = Math.ceil(count / pageLimit);

  const toTitleCase = (str) => {
    return str?.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const handleClearAllFilters = () => {
    vendorFilterValue && handleVendorFilter("");
    if (search) {
      setSearch("");
      setSearchTrigger(!searchTrigger);
    }
    if (statusFilter.length > 0) {
      setFilterLoading(true);
      setStatusFilter([]);
    }
    if (typeFilter) {
      handleTypeChange("");
    //   setProductsData(searchArray);
     }
    if (taggedWithFilter) {
      setTaggedWithFilter("");
    }
  };

  function getUnique(array) {
    let uniqueArray = [];

    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  const typeOptions = getUnique(
    searchArray.filter((p) => p.category).map((p) => p.category)
  );

  // const handleSort = async () => {
  //   let sorting = sortingFilterValue?.value;
  //   let sorted = [];

  //   if (sorting) {
  //     if (sorting.includes("-title")) {
  //       sorting === "asc-title"
  //         ? (sorted = searchArray.sort((a, b) =>
  //             a?.title.toLowerCase().localeCompare(b?.title.toLowerCase())
  //           ))
  //         : (sorted = searchArray.sort((a, b) =>
  //             b?.title.toLowerCase().localeCompare(a?.title.toLowerCase())
  //           ));
  //     } 
  //      else if (sorting.includes("-category")) {
  //       sorting === "asc-category"
  //         ? (sorted = searchArray.sort((a, b) =>
  //             a?.category.toLowerCase().localeCompare(b?.category.toLowerCase())
  //           ))
  //         : (sorted = searchArray.sort((a, b) =>
  //             b?.category.toLowerCase().localeCompare(a?.category.toLowerCase())
  //           ));
  //     } 
  //   } 

  //   setProductsData([...sorted]);
  // };

  // useEffect(() => {
  //   return handleSort();
  // }, [sortingFilterValue, searchArray]);

  const currentPageData =
    productsData.length > 0
      ? productsData.map((product, index) => {
          return {
            check: (
              <label className="custom-control mb-0 pointer pr-0">
                <Input
                  className="item-check pointer"
                  type="checkbox"
                  checked={product.selected}
                  label=""
                  style={{
                    border: "0.1px solid gray",
                    fontSize: "16px",
                  }}
                  disabled={filterLoading || paginationLoading}
                  onChange={(e) => onItemCheck(product, e, index)}
                />
              </label>
            ),
            image: (
              <div>
                <img
                  className="rounded-3"
                  src={
                    product?.image?.src
                      ? product.image.src
                      : "./img/products/no-image.png"
                  }
                  alt=""
                  height={50}
                  width={50}
                  style={{ border: "1px solid lightgrey" }}
                />
              </div>
            ),
            product: (
              <span style={{ whiteSpace: "nowrap" }}>
                {popOverContent(`Title-${index}`, product?.title)}
              </span>
            ),
            variants: (
              <span>
                {product?.variant.length}{" "}
                {product.variant.length > 0 &&
                  popOverList(
                    `Variant-${index}`,
                    product?.variant.map((o) => o?.title)
                  )}
              </span>
            ),
            type: (
              <span style={{ whiteSpace: "nowrap" }}>
                {popOverContent(`Category-${index}`, product?.category)}
              </span>
            ),
          };
        })
      : [];

  const onMasterCheck = async (e) => {
    let tempList = productsData;
    setMasterCheckBool(e.target.checked);
    tempList.map((user) => (user.selected = e.target.checked));
    setSelectedList(tempList.filter((e) => e.selected));
  };

  const onItemCheck = (item, e) => {
    let tempList = productsData;
    tempList.map((user, index) => {
      if (user === item) {
        user.selected = e.target.checked;
      }
      return user;
    });
    setSelectedList(tempList.filter((e) => e.selected));
  };

  const cols = React.useMemo(
    () =>
      productsData.length > 0
        ? [
            {
              Header: (
                <label className="custom-control mb-0 pointer align-self-center">
                  <Input
                    className="item-check pointer"
                    // style={{ marginTop: '-20px', marginLeft: '13px' }}
                    style={{
                      border: "0.1px solid gray",
                      fontSize: "16px",
                    }}
                    type="checkbox"
                    name="checkboxAllProducts"
                    checked={masterCheckBool}
                    disabled={filterLoading || paginationLoading}
                    onChange={(e) => onMasterCheck(e)}
                  />
                </label>
              ),
              cellClass: " w-1",
              accessor: "check",
              Cell: (props) => <>{props.value}</>,
            },
            {
              Header: "Image",
              cellClass: " w-1",
              accessor: "image",
              Cell: (props) => <>{props.value}</>,
            },
            {
              Header: "Product",
              accessor: "product",
              cellClass: "w-10",
              Cell: (props) => <>{props.value}</>,
            },
            {
              Header: "Variants",
              accessor: "variants",
              cellClass: " w-3",
              Cell: (props) => <>{props.value}</>,
            },
            {
              Header: "Type",
              accessor: "type",
              cellClass: " w-5",
              Cell: (props) => <div>{props.value}</div>,
            },
            
          ]
        : [],
    [masterCheckBool, productsData, paginationLoading, filterLoading]
  );

  const handleVendorFilter = async (e) => {
    setVendorFilterValue(e);
    setPageNumber(1);
    setPageLimit(50);
    setFilterLoading(true);
  };

  const handleTypeChange = (type) => {
    setTypeFilter(type);
    setProductsData(searchArray.filter((product) => product.category === type));
  };

  return (
    <>
      {loading ? (
        <TableSkeleton skeletonLength={10} itemsBar={3} tabs={4} />
      ) : emptyStatus ? (
        <div
          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
          style={{
            height: "200px",
          }}
        >
          <span>No Product Found!</span>
        </div>
      ) : (
        <div>
          <Row>
            <Cols xxs="12">
              <div
                className="p-1 mobile-margin"
                // variants={rightAnimate}
                // initial="hidden"
                // animate="visible"
              >
                <div className="card-title mb-0">
                  <div className="mt-3 d-flex justify-content-between flex-wrap">
                    <div className="mr-2 position-relative">
                      <Input
                        disabled={filterLoading || paginationLoading}
                        type="text"
                        name="keyword"
                        value={search}
                        id="search"
                        placeholder="Search"
                        onChange={(e) =>
                          handleSearch({
                            e,
                            setPageNumber,
                            setPageLimit,
                            setFilterLoading,
                            setSearch,
                            setSearchTrigger,
                            searchTrigger,
                          })
                        }
                        style={{ minWidth: "200px" }}
                        className="rounded-3"
                      />
                      {search.trim() && search.trim().length < 3 && (
                        <i
                          className="simple-icon-info pointer position-absolute text-danger fw-bold"
                          style={{ right: "-20px", bottom: "12px" }}
                          id={"SearchInfo"}
                        >
                          {" "}
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target={"SearchInfo"}
                          >
                            <PopoverBody className="text-center">
                              <i>Minimum 3 words required!</i>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </i>
                      )}
                      {search &&
                        (filterLoading && search.trim().length > 2 ? (
                          <Spinner
                            color="primary"
                            size={"sm"}
                            style={{ right: "10px", bottom: "12px" }}
                            className=" position-absolute"
                            type="grow"
                          ></Spinner>
                        ) : (
                          <span
                            className="far fa-close fs-6 position-absolute text-danger pointer"
                            style={{ right: "10px", bottom: "12px" }}
                            onClick={() => {
                              if (search) {
                                setSearch("");

                                setSearchTrigger(!searchTrigger);
                                setFilterLoading(true);
                              }
                              pageLimit > 50 && setPageLimit(50);
                              pageNumber > 1 && setPageNumber(1);
                            }}
                          ></span>
                        ))}
                    </div>
                    <div
                      className="mr-2"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {/* <Select
                        hideSelectedOptions
                        isSearchable={false}
                        isDisabled={filterLoading || paginationLoading}
                        value={
                          vendorFilterValue && {
                            label: vendorFilterValue,
                            value: vendorFilterValue,
                          }
                        }
                        isClearable
                        onChange={(e) => {
                          if (e) {
                            handleVendorFilter(e.value);
                          } else {
                            handleVendorFilter("");
                          }
                        }}
                        options={vendorOptions}
                        placeholder={
                          vendorFilterValue === "" && "Product Vendor"
                        }
                        className="fulfillmentStatusClass mr-2"
                      /> */}

                      {/* <ButtonGroup>
                        <UncontrolledButtonDropdown>
                          <DropdownToggle
                            disabled={loading}
                            style={{
                              backgroundColor: 'transparent',
                              color: 'grey',
                              border: '1px solid #c2baba',
                              borderRadius: '5px',
                              height: '38px',
                            }}
                            caret
                            color="muted"
                            className=" mt-2 mr-2"
                          >
                            Tagged with{' '}
                          </DropdownToggle>
                          <DropdownMenu>
                            <div className="container">
                              <div>
                                <Input
                                  type="text"
                                  value={taggedWithFilter}
                                  onChange={(e) => {
                                    setTaggedWithFilter(e.target.value);
                                  }}
                                />
                                <button
                                  className={
                                    taggedWithFilter
                                      ? 'textBtn mt-2 link rounded-3'
                                      : 'mt-2'
                                  }
                                  onClick={() => setTaggedWithFilter('')}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </ButtonGroup> */}
                      

                      <Button
                        disabled={filterLoading || paginationLoading}
                        outline
                        color="light"
                        className="rounded-2 mr-2"
                        style={{
                          color: "hsl(0, 0%, 50%)",
                          border: "1px solid hsl(0, 0%, 80%)",
                          height: "38px",
                        }}
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        More Filters
                      </Button>
                      {selectedList.length > 0 && (
                        <>
                          <div className="btn-group mr-2">
                            <button
                              disabled={filterLoading || paginationLoading}
                              type="button"
                              style={{
                                color: "hsl(0, 0%, 50%)",
                                border: "1px solid hsl(0, 0%, 80%)",
                                height: "38px",
                              }}
                              className="btn dropdown-toggle rounded-2"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              More Actions{" "}
                            </button>
                            <div className="dropdown-menu">
                              <Link className="dropdown-item" to="#">
                                Set As Active
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Set As Draft
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Set As Archived
                              </Link>
                              <div className="dropdown-divider"></div>
                              <Link className="dropdown-item" to="#">
                                Edit Products
                              </Link>
                              <Link className="dropdown-item" to="#">
                                Delete Products
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="d-flex">
                      <span style={{ minWidth: "153px" }}>
                        <Select
                          hideSelectedOptions
                          isDisabled={filterLoading || paginationLoading}
                          isClearable
                          value={sortingFilterValue}
                          onChange={(e) => {
                            if (e) {
                              setSortingFilterValue(e);
                            } else {
                              setSortingFilterValue("");
                            }
                          }}
                          options={sortOptions}
                          placeholder="Sort Filters"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <CardBody className="pt-0">
                  {paginationLoading && (
                    <div className="mt-4 mx-3">
                      <Alert color="info">
                        <Spinner
                          color="light"
                          size={"sm"}
                          style={{ marginBottom: "3px" }}
                        ></Spinner>{" "}
                        &nbsp;
                        <span style={{ fontSize: "16px", color: "black" }}>
                          Products Loading!
                        </span>
                      </Alert>
                    </div>
                  )}
                  {filterLoading ? (
                    <TableSkeleton skeletonLength={10} />
                  ) : (
                    <div>
                      {productsData.length === 0 ? (
                        <div
                          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                          style={{
                            height: "200px",
                          }}
                        >
                          <span>No Product Matched!</span>
                        </div>
                      ) : (
                        <DataTable
                          fetchData={fetchProducts}
                          columns={cols}
                          data={currentPageData}
                          setPageLimitInParent={setPageLimit}
                          setPageNumberInParent={setPageNumber}
                          pageCount={totalPages}
                          setPaginationLoading={setPaginationLoading}
                          paginationLoading={paginationLoading}
                          selectedLength={selectedList.length}
                          totalCount={count}
                        />
                      )}
                    </div>
                  )}
                </CardBody>
              </div>
            </Cols>
          </Row>
        </div>
      )}

      
    </>
  );
};

export default Products;
