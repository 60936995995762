import React, { useCallback, useEffect, useState } from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
import DataTable from "../../tables/table.jsx";
import {
  Alert,
  Spinner,
  Input,
  UncontrolledPopover,
  PopoverBody,
  Fade,
  Button,
  Table,
} from "reactstrap";
import {
  getCouriersLoadsheet,
  getInvoiceSlip,
  getOrders,
  updateOrderOnshopify,
} from "../../../api/orders";
import Select from "react-select";
import {
  fulFillmentStatus,
  paymentStatus,
} from "./helperFunctions/ordersStatusInfo.jsx";
import { showCustomerDetail } from "./helperFunctions/tableDetailsInfo.jsx";
import DateFilters from "../../Date/DateFilters.jsx";
import { useHistory } from "react-router-dom";
import { getCookie } from "../../../helpers/cookie";
import { toast } from "react-toastify";
import { getAllStoresLoginUser } from "../../../api";
// import { NotePopup } from "../../Modals/Popups";
import { camelCase, startCase } from "lodash";
import { handleSearch } from "../../search/searchHook";
// import { rightAnimate } from "../../FramerMotion/FramerMotion";
import { motion } from "framer-motion";
import { popOverContent, popOverList } from "../../../popOvers/popOver";
import TableSkeleton from "../../UI/TableSkeleton";
import {
  mergePDFs,
  handleDownloadPdf,
  chunkArray,
} from "../../../helpers/downloadMergedPDF";
import { CustomModal } from "../../Modals/Modal";
import { couriersLoadSheet } from "../../../api/courier.js";
const AllOrders = ({ setSkeletonTab }) => {
  const proccesState = {
    total: 0,
    completed: 0,
    action: "",
  };
  let paymentOptons = [
    // { value: "Cash on Delivery (COD)", label: "Cash on Delivery (COD)" },
    // { value: "manual", label: "Manual" },
    { value: "paid", label: "Mark as paid" },
  ];
  const [allOrders, setAllOrders] = useState([]);
  const [search, setSearch] = useState("");
  // const [itemDetailsData, setItemDetailsData] = useState();
  // const [userDetail, setUserDetail] = useState();
  const [statusOption, setStatusOption] = useState("");
  const [paymentStatusOption, setPaymentStatusOption] = useState([]);
  const [fulfillmentStatusOption, setFulfillmentStatusOption] = useState([]);
  const [paymentStatusClass, setPaymentStatusClass] = useState("paymentStatus");
  const [fulfillmentStatusClass, setFulfillmentStatusClass] =
    useState("fulfillmentStatus");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [filtersLoader, setFiltersLoader] = useState(false);
  // const [dateTime, setDateTime] = useState();
  const [count, setCount] = useState(0);
  // const [showResetButton, setShowResetButton] = useState(false);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showStoreColumn, setShowStoreColumn] = useState(false);
  // const [noteDetailsData, setNoteDetailsData] = useState("");
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);

  const [completedPDF, setCompletedPDF] = useState(proccesState);
  const [masterCheckBool, setMasterCheckBool] = useState(false);
  const [bulkSelected, setBulkSelected] = useState([]);
  const [editOrderModal, setEditOrderModal] = useState(false);
  const [updatingOrders, setUpdatingOrders] = useState([]);
  const [applyAllVal, setApplyAllVal] = useState("");
  const chunkSize = 50; // Set the chunk size to 50
  const statusOptions = [
    { value: "open", label: "Open" },
    { value: "archived", label: "Archived" },
    { value: "canceled", label: "Canceled" },
  ];

  const paymentStatusOptions = [
    { value: "authorized", label: "Authorized" },
    { value: "expired", label: "Expired" },
    { value: "paid", label: "Paid" },
    { value: "partially_paid", label: "Partially paid" },
    { value: "partially_refunded", label: "Partially refunded" },
    { value: "pending", label: "Pending" },
    { value: "refunded", label: "Refunded" },
    { value: "unpaid", label: "Unpaid" },
    { value: "voided", label: "Voided" },
  ];
  const fulfillmentStatusOptions = [
    { value: "fulfilled", label: "Fulfilled" },
    { value: "unfulfilled", label: "Unfulfilled" },
    { value: "partial", label: "Partially fulfilled" },
  ];

  const toggleEditOrderModal = () => {
    setEditOrderModal(!editOrderModal);
    setUpdatingOrders([]);
    setApplyAllVal("");
  };

  const onMasterCheck = async (e) => {
    let tempList = allOrders;
    setMasterCheckBool(e.target.checked);
    tempList.map((user) => (user.selected = e.target.checked));
    setAllOrders(tempList);
    setBulkSelected(tempList.filter((o) => o.selected));
  };

  const handleUpdateOrders = async (updatingArray) => {
    try {
      const { data } = await updateOrderOnshopify({ updating: updatingArray });
    } catch (error) {
      toast.error("Failed to update Orders");
      console.log(error);
    }
  };

  const updateOrderOnebyOne = async () => {
    const finalupdating = updatingOrders.filter((u) => u?.gatewayType);

    toggleEditOrderModal();
    setPaginationLoading(true);
    setCompletedPDF({
      ...completedPDF,
      total: finalupdating.length,
      action: "Updating",
    });
    for (const o of finalupdating) {
      await handleUpdateOrders([
        {
          orderId: o.orderId,
          storeId: o.storeId,
          financial_status: o.financial_status,
        },
      ]);
      setCompletedPDF((prevState) => ({
        ...prevState,
        completed: prevState.completed + 1,
      }));
    }
    await loadOrders({});
    setPaginationLoading(false);
    setCompletedPDF(proccesState);
    onMasterCheck({ target: { checked: false } });
    toast.success(`Updated Successfully!`);
    toast.info(`Orders will take seconds to update in shopilam.`, {
      autoClose: false,
      position: "top-center",
    });
  };

  const orderPrintSlip = async (dataForLables) => {
    try {
      let orderIds = [];
      // let sku = [];
      // let length = dataForLables.length;
      let pageLength = dataForLables.map((order) => {
        orderIds.push(order._id);
        return order;
        // sku.push(order.line_items[0]?.sku);

        // if (order.line_items.length > 3) {
        //   length = length + 1;
        //   return length;
        // } else {
        //   return length;
        // }
      });
      // const printPages = Math.max(...pageLength);

      const { data } = await getInvoiceSlip({
        orderIds,
      });
      return { pdfString: data.base64PDF };
    } catch (error) {
      toast.error(`${error.message || error.response.data || error}`);
    }
  };

  const handleOrderSlipDownload = async (selectedOrdersInBulk) => {
    try {
      setPaginationLoading(true);
      let pdfStrings = [];
      let chunkingOrder = [];

      for (let order of selectedOrdersInBulk) {
        const { pdfString } = await orderPrintSlip([order]);
        setCompletedPDF((prevState) => ({
          ...prevState,
          completed: prevState.completed + 1,
        }));
        pdfStrings.push(pdfString);
        chunkingOrder.push(order);

        if (pdfStrings.length >= chunkSize) {
          const pdfdownloadString = await mergePDFs(pdfStrings);
          if (pdfdownloadString) {
            const downLoadedOrderNames = await handleDownloadPdf(
              pdfdownloadString,
              chunkingOrder
            );
            chunkingOrder = [];
            pdfStrings = [];
          }
        }
      }

      // if (pdfStrings.length > chunkSize) {
      //   const pdfChunks = await chunkArray(pdfStrings, chunkSize);
      //   const orderChunks = await chunkArray(selectedOrdersInBulk, chunkSize);

      //   for (let i = 0; i < pdfChunks.length; i++) {
      //     const chunk = pdfChunks[i];
      //     const pdfdownloadString = await mergePDFs(chunk);
      //     if (pdfdownloadString) {
      //       const downLoadedOrderNames = await handleDownloadPdf(
      //         pdfdownloadString,
      //         orderChunks[i]
      //       );
      //     }
      //   }
      // } else {
      if (pdfStrings.length) {
        const pdfdownloadString = await mergePDFs(pdfStrings);
        if (pdfdownloadString) {
          const downLoadedOrderNames = await handleDownloadPdf(
            pdfdownloadString,
            chunkingOrder
          );
        }
      }
      // }
      toast.success(`Invoice Downloaded Successfully!`);
      onMasterCheck({ target: { checked: false } });
    } catch (error) {
      console.log(error);
      toast.error(`${error.message || "Failed to download invoice"}`);
    }

    setPaginationLoading(false);
    setCompletedPDF(proccesState);
  };

  let history = useHistory();
  const loadOrders = useCallback(
    async ({
      page = pageNumber,
      limit = pageLimit,
      startDateIs = startDate,
      endDateIs = endDate,
      title = search.trim().length > 2 ? search : "",
      status = statusOption,
      payment = paymentStatusOption,
      fulfillment = fulfillmentStatusOption,
    }) => {
      setEmptyStatus(false);
      try {
        const isoStartDate = startDateIs ? startDateIs : startDateIs;
        const isoEndDate = endDateIs ? endDateIs : endDateIs;

        const { data } = await getOrders({
          title,
          status,
          payment,
          fulfillment,
          page,
          limit,
          startDateIs: isoStartDate,
          endDateIs: isoEndDate,
        });
        if (data) {
          setAllOrders(data.orders);
          setFiltersLoader(false);
          setLoading(false);
          // setSkeletonTab(false);
          setPaginationLoading(false);
          setCount(data.count);
        }
        if (data.count === 0) {
          setEmptyStatus(true);
        } else {
          setEmptyStatus(false);
        }

        return data.orders;
      } catch (error) {
        console.log("error found when fetch order data", error);
      }
    },
    [
      pageNumber,
      pageLimit,
      endDate,
      startDate,
      statusOption,
      paymentStatusOption,
      fulfillmentStatusOption,
      searchTrigger,
    ]
  );

  const totalPages = Math.ceil(count / pageLimit);

  const loadStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const { stores } = data[0];
      stores.length > 1 && setShowStoreColumn(true);
    } catch (error) {
      toast.error("Error found when fetch stores data!");
      console.log("Error found when fetch stores data!", error);
    }
  }, []);

  useEffect(() => {
    // setSkeletonTab(true);
    loadStores();
  }, []);

  useEffect(() => {
    let token = getCookie();

    if (token) {
      loadOrders({});
    } else {
      history.push("/signIn");
      toast.error("Your Session is Expired! Login Again...");
    }
  }, [loadOrders]);

  // const reloadOrders = async () => {
  //   setSearch('');
  //   setStatusOption('');
  //   setFulfillmentStatusOption([]);
  //   setPaymentStatusOption([]);
  //   setPaymentStatusClass('paymentStatus');
  //   setFulfillmentStatusClass('fulfillmentStatus');
  // };

  const resetFilters = async () => {
    setFiltersLoader(true);
    setStartDate(null);
    setEndDate(null);
    setSelectedDateFilter(null);
    setSearch("");
    setStatusOption("");
    setFulfillmentStatusOption([]);
    setPaymentStatusOption([]);
    setPaymentStatusClass("paymentStatus");
    setFulfillmentStatusClass("fulfillmentStatus");
  };

  const handleStatus = async (statusOption) => {
    setStatusOption(statusOption.value);
    setFiltersLoader(true);
    setPageNumber(1);
    setPageLimit(50);
  };

  const handlePaymentStatus = async (paymentStatusOption) => {
    setFiltersLoader(true);
    setPageNumber(1);
    setPageLimit(50);
    if (paymentStatusOption.length === 0) {
      setPaymentStatusClass("paymentStatus");
    } else {
      setPaymentStatusClass("");
    }

    let paymentStatusValue = paymentStatusOption.map((data) => data.value);
    setPaymentStatusOption(paymentStatusValue);
  };

  const handleFulfillmentStatus = async (fulfillmentStatusOption) => {
    setFiltersLoader(true);
    setPageNumber(1);
    setPageLimit(50);
    if (fulfillmentStatusOption.length === 0) {
      setFulfillmentStatusClass("fulfillmentStatus");
    } else {
      setFulfillmentStatusClass("");
    }

    let fulfillmentStatusValue = fulfillmentStatusOption.map(
      (data) => data.value
    );

    setFulfillmentStatusOption(fulfillmentStatusValue);
  };

  // const noteDetails = (note) => {
  //   let noteText = note.slice(0, 20);
  //   let noteLenght = note.length;
  //   return (
  //     <div>
  //       {`${noteText}${noteLenght > 20 ? "..." : ""}`}

  //       {noteLenght > 20 ? (
  //         <i
  //           style={{ cursor: "pointer" }}
  //           className="p-2 simple-icon-info "
  //           id="noteDetailPopover"
  //           onMouseEnter={() => {
  //             setNoteDetailsData(note);
  //           }}
  //         />
  //       ) : (
  //         ""
  //       )}
  //       <NotePopup noteData={noteDetailsData && noteDetailsData} />
  //     </div>
  //   );
  // };

  const onItemCheck = (evt, index) => {
    let temp = allOrders;
    temp[index].selected = evt.target.checked;
    setMasterCheckBool([...temp].every((obj) => obj.selected));
    setAllOrders([...temp]);
    setBulkSelected(temp.filter((o) => o.selected));
  };
  const ordersData =
    allOrders &&
    allOrders.length > 0 &&
    allOrders.map((order, i) => {
      return {
        check: (
          <label className="custom-control mb-0 pointer pr-0">
            <Input
              className="item-check pointer"
              type="checkbox"
              checked={order.selected}
              label=""
              style={{
                border: "0.1px solid gray",
                fontSize: "16px",
              }}
              disabled={filtersLoader || paginationLoading}
              onChange={(e) => onItemCheck(e, i)}
            />
          </label>
        ),
        orderName: order?.name ? order?.name : "N/A",
        orderDate: DateFormatted(order?.created_at, i),
        name: order?.shipping_address ? showCustomerDetail(order, i) : "N/A",
        amount: popOverContent(
          `Total-${i}`,
          Number(order?.total_price_set?.shop_money?.amount).toLocaleString()
        ),
        paymentStatus: paymentStatus(order?.financial_status),
        fulfillmentStatus: fulFillmentStatus(order?.fulfillment_status),
        note: order?.note || "N/A",
        items: (
          <>
            {String(order?.line_items.length).slice(0, 20)}
            {String(order?.line_items.length).length > 20 ? " ... " : " "}
            {order?.line_items.length > 0
              ? popOverList(
                  `lineItem-${i}`,
                  order?.line_items.map((o) => `${o.title} (${o.quantity})`)
                )
              : "N/A"}
          </>
        ),
        delivery: order?.gateway ? order?.gateway : "N/A",
        storeName: popOverContent(
          `Store-${i}`,
          order?.orderRefNumber.replace(`${order?.name}-`, "")
        ),
      };
    });

  const cols = React.useMemo(
    () => [
      {
        Header: (
          <label className="custom-control mb-0 pointer align-self-center">
            <Input
              className="item-check pointer"
              // style={{ marginTop: '-20px', marginLeft: '13px' }}
              style={{
                border: "0.1px solid gray",
                fontSize: "16px",
              }}
              type="checkbox"
              name="checkboxAllProducts"
              checked={masterCheckBool}
              disabled={paginationLoading}
              onChange={(e) => onMasterCheck(e)}
            />
          </label>
        ),
        cellClass: " w-1",
        accessor: "check",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Order Id",
        accessor: "orderName",
        cellClass: " w-2",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-12",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Payment",
        accessor: "paymentStatus",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Fulfillment",
        accessor: "fulfillmentStatus",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Delivery Method",
        accessor: "delivery",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    [allOrders, masterCheckBool]
  );

  const myCols = React.useMemo(
    () => [
      {
        Header: (
          <label className="custom-control mb-0 pointer align-self-center">
            <Input
              className="item-check pointer"
              // style={{ marginTop: '-20px', marginLeft: '13px' }}
              style={{
                border: "0.1px solid gray",
                fontSize: "16px",
              }}
              type="checkbox"
              name="checkboxAllProducts"
              checked={masterCheckBool}
              disabled={paginationLoading}
              onChange={(e) => onMasterCheck(e)}
            />
          </label>
        ),
        cellClass: " w-1",
        accessor: "check",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Order Id",
        accessor: "orderName",
        cellClass: " w-2",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-12",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Total",
        accessor: "amount",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Payment",
        accessor: "paymentStatus",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Fulfillment",
        accessor: "fulfillmentStatus",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Delivery Method",
        accessor: "delivery",
        cellClass: " w-6",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Store",
        accessor: "storeName",
        cellClass: " w-10",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    [allOrders, masterCheckBool]
  );

  const toTitleCase = (str) => {
    return startCase(camelCase(str));
  };
  const [couriersLoadSheetData, setCouriersLoadSheetData] = useState([]);
  const handleDownloadLoadSheets = async () => {
    
    console.log("in handle download load sheet pdf ");
    setPaginationLoading(true);
    const orderIds = bulkSelected.filter((b) => b?.courier).map((o) => o?._id);
    try {
      const { data } = await couriersLoadSheet({ orderIds });
      setCouriersLoadSheetData(data);
    } catch (error) {
      console.log(error);
    }
    setPaginationLoading(false);
  };

  const handleLoadSheetPDF = async () => {
    console.log("in handle load sheet pdf ");
    setPaginationLoading(true);
    try {
      let pdfStrings = [];
      let chunkingOrder = [];

      for (let courier of couriersLoadSheetData) {
        const {
          data: { base64PDF },
        } = await getCouriersLoadsheet({
          courier: courier.courier,
          orderIds: courier.orders.map((o) => o?._id),
        });
        setCompletedPDF((prevState) => ({
          ...prevState,
          completed: prevState.completed + 1,
        }));
        // pdfStrings.push(pdfString);
        chunkingOrder = courier?.orders;

        // if (courier?.totalOrders >= chunkSize) {
        // const pdfdownloadString = await mergePDFs(pdfStrings);
        if (base64PDF) {
          await handleDownloadPdf(base64PDF, chunkingOrder);
          chunkingOrder = [];
          // pdfStrings = [];
        }
        // }
      }
    } catch (error) {
      console.log(error);
    }
    setCouriersLoadSheetData([]); 
    setPaginationLoading(false);
  };

  return (
    <>
      <CustomModal
        scrollable={true}
        isOpen={couriersLoadSheetData.length}
        size="lg"
        title={"Courier's Load sheets"}
        includeFooter={true}
        primaryBtnWithSpinner={true}
        primaryBtnText="Create Load Sheet"
        primaryBtnHandler={handleLoadSheetPDF}
        isProcessing={paginationLoading}
        secondaryBtnText="Close"
        secondaryBtnHandler={() => {
          setCouriersLoadSheetData([]);
        }}
        body={
          <>
            {/* {paginationLoading && <div className="loading"></div>} */}
            {couriersLoadSheetData.length === 0 && (
              <div
                className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                style={{
                  height: "200px",
                }}
              >
                <span>No data Found!</span>
              </div>
            )}

            {couriersLoadSheetData.map((c, i) => (
              <>
                <h1>
                  {c?.courier} <b>{`(${c?.totalOrders})`}</b>
                </h1>
                <table className="table" key={i + 1}>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Destination</th>
                      <th scope="col">Tracking</th>
                    </tr>
                  </thead>
                  <tbody>
                    {c?.orders.map((o, ind) => (
                      <tr key={ind + 1}>
                        <th scope="row">{o?.name}</th>
                        <td>
                          {popOverContent(
                            `customer${ind + 1}`,
                            (o.customer?.first_name || "N/A") +
                              " " +
                              (o.customer?.last_name || "N/A")
                          )}
                        </td>
                        <td>
                          {popOverContent(
                            `address${ind + 1}`,
                            (o?.shipping_address?.address1 || "") +
                              (o?.shipping_address?.address2 || "")
                          )}
                        </td>
                        <td>
                          {popOverContent(
                            `tracking${ind + 1}`,
                            o?.shipmentTracking[0]?.trackingNumber
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ))}
          </>
        }
      />
      {loading ? (
        <TableSkeleton skeletonLength={10} itemsBar={3} />
      ) : emptyStatus &&
        !search.trim() &&
        !startDate &&
        !endDate &&
        !statusOption &&
        paymentStatusOption.length === 0 &&
        fulfillmentStatusOption.length === 0 ? (
        <div
          className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
          style={{
            height: "200px",
          }}
        >
          <span>No Order Found!</span>
        </div>
      ) : (
        <motion.div
          className="row"
          // variants={rightAnimate}
          // initial="hidden"
          // animate="visible"
        >
          <Cols xxs="12">
            <div className="mt-4 mb-4 marginL-md px-2">
              <div className="card-title mb-0">
                <div className="rightAnimate d-flex justify-content-between flex-wrap">
                  <div className="mb-2 me-1 position-relative">
                    <Input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        setEmptyStatus(false);
                        handleSearch({
                          e,
                          setPageNumber,
                          setPageLimit,
                          setFilterLoading: setFiltersLoader,
                          setSearch,
                          setSearchTrigger,
                          searchTrigger,
                        });
                      }}
                      className="rounded-3"
                      disabled={paginationLoading || filtersLoader}
                    />

                    {search.trim() && search.trim().length < 3 && (
                      <i
                        className="simple-icon-info pointer position-absolute text-danger fw-bold"
                        style={{ right: "-20px", bottom: "12px" }}
                        id={"SearchInfo"}
                      >
                        {" "}
                        <UncontrolledPopover
                          trigger="hover"
                          placement="top"
                          target={"SearchInfo"}
                        >
                          <PopoverBody className="text-center">
                            <i>Minimum 3 words required!</i>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </i>
                    )}
                    {search &&
                      (filtersLoader && search.trim().length > 2 ? (
                        <Spinner
                          color="primary"
                          size={"sm"}
                          style={{ right: "10px", bottom: "12px" }}
                          className=" position-absolute"
                          type="grow"
                        ></Spinner>
                      ) : (
                        <span
                          className="far fa-close fs-6 position-absolute text-danger pointer"
                          style={{ right: "10px", bottom: "12px" }}
                          onClick={() => {
                            if (search) {
                              setSearch("");

                              setSearchTrigger(!searchTrigger);
                              setFiltersLoader(true);
                            }
                            pageLimit > 50 && setPageLimit(50);
                            pageNumber > 1 && setPageNumber(1);
                          }}
                        ></span>
                      ))}
                  </div>
                  <div>
                    <DateFilters
                      startDate={startDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      endDate={endDate}
                      selectedDateFilter={selectedDateFilter}
                      setSelectedDateFilter={setSelectedDateFilter}
                      // setShowResetButton={setShowResetButton}
                      loadOrders={loadOrders}
                      resetFilters={resetFilters}
                      setFiltersLoader={setFiltersLoader}
                      paginationLoading={paginationLoading}
                      filtersLoader={filtersLoader}
                      allOrdersFlag={"allOrdersFlag"}
                    />
                  </div>
                  <div className="d-flex flex-wrap alignTop">
                    <Select
                      value={
                        statusOption
                          ? {
                              value: statusOption,
                              label: toTitleCase(statusOption),
                            }
                          : null
                      }
                      onChange={(e) =>
                        e ? handleStatus(e) : handleStatus({ value: "" })
                      }
                      options={statusOptions}
                      isClearable
                      placeholder="Status"
                      className=" mb-2 mr-2"
                      isDisabled={paginationLoading || filtersLoader}
                    />
                    <Select
                      value={paymentStatusOption.map((data) => {
                        return { value: data, label: toTitleCase(data) };
                      })}
                      onChange={(e) => handlePaymentStatus(e)}
                      options={paymentStatusOptions}
                      isMulti={true}
                      isDisabled={paginationLoading || filtersLoader}
                      placeholder="Payment"
                      className={`alignTop-xxs mb-2 mr-2 ${
                        paymentStatusClass && paymentStatusClass
                      }`}
                    />
                    <Select
                      value={fulfillmentStatusOption.map((data) => {
                        // data === 'shipped' && (data = 'fulfilled');
                        return { value: data, label: toTitleCase(data) };
                      })}
                      onChange={(e) => handleFulfillmentStatus(e)}
                      options={fulfillmentStatusOptions}
                      isMulti={true}
                      isDisabled={paginationLoading || filtersLoader}
                      placeholder="Fulfillment"
                      className={`alignTop-xs ${
                        fulfillmentStatusClass && fulfillmentStatusClass
                      }`}
                    />
                  </div>
                </div>
              </div>

              {paginationLoading && (
                <div className="mt-3 mx-3">
                  <Alert color="info">
                    <Spinner
                      color="light"
                      size={"sm"}
                      style={{ marginBottom: "3px" }}
                    ></Spinner>{" "}
                    &nbsp;
                    <span style={{ fontSize: "16px", color: "black" }}>
                      {completedPDF.total
                        ? `${completedPDF.action}... ${completedPDF.completed} / ${completedPDF.total}`
                        : `Orders Loading!`}
                    </span>
                  </Alert>
                </div>
              )}

              {filtersLoader ? (
                <TableSkeleton skeletonLength={10} />
              ) : allOrders.length === 0 ? (
                <div
                  className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                  style={{
                    height: "200px",
                  }}
                >
                  <span>No Order Matched!</span>
                </div>
              ) : (
                <DataTable
                  fetchData={loadOrders}
                  setPageLimitInParent={setPageLimit}
                  setPageNumberInParent={setPageNumber}
                  pageCount={totalPages}
                  columns={showStoreColumn ? myCols : cols}
                  data={ordersData?.sort(sortByDate)}
                  setPaginationLoading={setPaginationLoading}
                  paginationLoading={paginationLoading}
                  totalCount={count}
                />
              )}
            </div>
          </Cols>
        </motion.div>
      )}
      {bulkSelected.length ? (
        <Fade in={true}>
          <div
            className="alert gap-2 alert-secondary mx-auto w-auto position-fixed rounded-pill d-flex align-items-center justify-content-center flex-wrap text-black shadow-lg"
            style={{
              bottom: "30px",
              zIndex: "2",
            }}
            role="alert"
          >
            <button
              className="btn btn-primary"
              disabled={paginationLoading || filtersLoader}
              onClick={() => {
                if (bulkSelected.length) {
                  setCompletedPDF({
                    ...completedPDF,
                    total: bulkSelected.length,
                    action: "Creating invoice",
                  });
                  handleOrderSlipDownload(bulkSelected);
                } else {
                  toast.info("Select orders to download invoice!");
                }
              }}
            >
              Download Invoice
              {` (${bulkSelected.length}) `}
              <span className="iconsminds-down-1 ml-2"></span>
            </button>
            <button
              className="btn btn-primary"
              disabled={paginationLoading || filtersLoader}
              onClick={() => {
                setUpdatingOrders(
                  bulkSelected.filter((o) => o.financial_status !== "paid")
                );
                setEditOrderModal(true);
              }}
            >
              Edit
              <span className="simple-icon-pencil ml-2"></span>
            </button>
            <button
              className="btn btn-primary"
              disabled={paginationLoading || filtersLoader}
              onClick={handleDownloadLoadSheets}
            >
              Courier's Load Sheet
            </button>
          </div>
        </Fade>
      ) : (
        ""
      )}
      <CustomModal
        scrollable={true}
        size="lg"
        title={`Edit Orders (${
          updatingOrders.filter((u) => u?.gatewayType).length
        })`}
        toggle={toggleEditOrderModal}
        includeFooter={true}
        isOpen={editOrderModal}
        primaryBtnText="Update"
        secondaryBtnText="Cancel"
        secondaryBtnHandler={toggleEditOrderModal}
        isProcessing={!updatingOrders.some((o) => o.gatewayType)}
        primaryBtnHandler={updateOrderOnebyOne}
        body={
          updatingOrders?.length ? (
            <>
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <div className="w-75">
                  <Select
                    onChange={(e) => {
                      if (e) {
                        setApplyAllVal(e.value);
                      } else {
                        setApplyAllVal("");
                      }
                    }}
                    options={paymentOptons}
                  />
                </div>
                <div className="ps-1 w-25 text-center">
                  <Button
                    disabled={!applyAllVal}
                    color="secondary"
                    className="fw-bold"
                    onClick={() => {
                      let temp = JSON.parse(JSON.stringify(updatingOrders));
                      setUpdatingOrders(
                        temp.map((o) => ({
                          ...o,
                          financial_status: applyAllVal,
                          gatewayType: applyAllVal,
                        }))
                      );
                    }}
                  >
                    {/* {applyAllVal ? "Apply to all" : "Reset all"} */}
                    Apply to all
                  </Button>
                </div>
              </div>
              {/* <p className="form-label mt-1 ms-1">
              In most cases, where the product is manufactured.
            </p> */}

              <Table content="center" borderless>
                {/* <caption>List of users</caption> */}
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Payment Status</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {updatingOrders.map((order, i) => (
                    <tr key={i + 1}>
                      <td>{order?.name}</td>
                      <td>{paymentStatus(order?.financial_status)}</td>
                      <td>{order?.gateway || "N/A"}</td>
                      <td>
                        <div className="">
                          <Select
                            onChange={(e) => {
                              let temp = JSON.parse(
                                JSON.stringify(updatingOrders)
                              );
                              temp[i].gatewayType = e.value;
                              temp[i].financial_status = e.value;

                              setUpdatingOrders([...temp]);
                            }}
                            value={order?.financial_status || ""}
                            options={paymentOptons}
                            placeholder={order?.financial_status || "Select"}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <div className="text-danger fw-bold d-flex h-100 justify-content-center align-items-center">
              <h2>Orders not available to mark as paid.</h2>
            </div>
          )
        }
      />
    </>
  );
};

export default AllOrders;
