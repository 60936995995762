import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  confirmInviteForReselling,
  getAllResellers,
  removeFromReselling,
  sendInviteForReselling,
} from "../../api/reselling";
import { toast } from "react-toastify";
import { popOverContent } from "./../../popOvers/popOver";
import { motion } from "framer-motion";
import {
  Button,
  Fade,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { leftAnimate } from "../FramerMotion/FramerMotion";

const Reselling = () => {
  const [loading, setLoading] = useState(true);
  const [resellingUsers, setResellingUsers] = useState(null);
  const [modal, setModal] = useState(false);
  const [inviteEmail, setInviteEmail] = useState({ email: "" });
  const [inviteLoading, setInviteLoading] = useState(false);
  const [tabNum, setTabNum] = useState(1);
  const [message, setMessage] = useState("");
  const [confirmRemove, setConfirmRemove] = useState("");

  const fetch = async () => {
    try {
      const { data } = await getAllResellers();
      setResellingUsers(data.seller);
      setLoading(false);
    } catch (error) {
      toast.error(error.message || "Failed to fetch reselling data!");
      console.log(error);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  const toggle = () => {
    setModal(!modal);
    setInviteEmail({ email: "" });
    setMessage("");
  };

  const handleconfirmToggle = () => {
    setConfirmRemove("");
  };

  const handleSendInvite = async (e) => {
    e.preventDefault();
    if (inviteEmail.email.trim()) {
      try {
        setInviteLoading(true);
      const { data } =  await sendInviteForReselling(inviteEmail);
        let result = data.message;
toast.success(result + `${inviteEmail.email}`);
       // toast.success(`Successfully sended Invite to ${inviteEmail.email}` );
        toggle();
      } catch (error) {
        setMessage(error.response.data.message || "Failed to send invite!");
        console.log(error);
      }
      setInviteLoading(false);
    } else {
      setMessage("Email required!");
    }
  };

  const handleConfirmInvite = async (flag, userId) => {
    try {
      setInviteLoading(true);

      const { data } = await confirmInviteForReselling({
        flag,
        reseller: { userId },
      });
      toast.success(data.message);
      setLoading(true);
      await fetch();
    } catch (error) {
      setMessage(error.response.data.message || "Failed to confirm invite!");
      console.log(error);
    }
    setInviteLoading(false);
  };

  const handleRemoveReseller = async (resellerAccountId) => {
    try {
      setInviteLoading(true);

      const { data } = await removeFromReselling({
        resellerAccountId,
      });
      toast.success(data.message);
      setLoading(true);
      handleconfirmToggle();
      await fetch();
    } catch (error) {
      setMessage(error.response.data.message || "Failed to remove!");
      console.log(error);
    }
    setInviteLoading(false);
  };
  

  return (
    <>
      <main>
        <div className="container-fluid">
          <div className="dashboard-wrapper">
            <div className="row top-text mb-3">
              <motion.div
                className="col-12"
                variants={leftAnimate}
                initial="hidden"
                animate="visible"
              >
                <h1>Manage Reselling</h1>
                <div className="text-zero top-right-button-container">
                  <button
                    type="button"
                    className="top-right-button btn btn-outline-primary btn-lg"
                    onClick={toggle}
                  >
                    Add Reseller
                  </button>
                  <Modal isOpen={modal}>
                    <ModalHeader toggle={toggle}>
                      Invite for Reselling
                    </ModalHeader>
                    <form onSubmit={handleSendInvite}>
                      <ModalBody>
                        <FormGroup className="form-group has-float-label">
                          <Label>Invite</Label>
                          <Input
                            className="mb-1"
                            autoFocus={!inviteEmail.email}
                            type="email"
                            placeholder="Email"
                            value={inviteEmail.email}
                            onChange={(evt) =>
                              setInviteEmail({ email: evt.target.value })
                            }
                          />
                          {message && (
                            <Fade
                              className="alert alert-danger alert-dismissible "
                              role="alert"
                            >
                              <button
                                type="button"
                                className="close"
                                onClick={() => {
                                  setMessage("");
                                }}
                              >
                                <span>×</span>
                              </button>
                              {message}
                            </Fade>
                          )}
                        </FormGroup>
                        <strong>Note:</strong>
                        <br />
                       
  {/*  <p>User you want to invite must be a <b>Shopilam</b>{" "}
  user, if user accept your invite you and your
  reseller's products will be on reselling based on SKU
  matching, and inventory will be sync on every order
  create. </p> */}

<p>
  Anyone can be a <b>reseller</b>. Once they sign up, they will have the option to accept or deny the request.
</p>

                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={inviteLoading}
                        >
                          Send Invite
                        </Button>{" "}
                        <Button
                          color="secondary"
                          onClick={toggle}
                          disabled={inviteLoading}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </form>
                  </Modal>
                  <Modal isOpen={confirmRemove}>
                    <ModalHeader toggle={handleconfirmToggle}>
                      Remove :{" "}
                      <b>
                        {popOverContent("reseller", confirmRemove?.userName)}
                      </b>
                    </ModalHeader>
                    <ModalBody>
                      <strong>Note:</strong>
                      <br />
                      <p>
                        Removing
                        <b> {confirmRemove?.userName} </b> your inventory will
                        not sync with this reseller.
                      </p>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        disabled={inviteLoading}
                        onClick={() => {
                          handleRemoveReseller(confirmRemove?.accountId);
                        }}
                      >
                        Remove
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={handleconfirmToggle}
                        disabled={inviteLoading}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
                <nav
                  className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
                  aria-label="breadcrumb"
                >
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">Home</li>
                    <li className="active breadcrumb-item" aria-current="page">
                      Reselling
                    </li>
                  </ol>
                </nav>
              </motion.div>
              <ul className="separator-tabs ml-0 nav nav-tabs">
                <li
                  className="nav-item"
                  onClick={() => {
                    setTabNum(1);
                  }}
                >
                  <span
                    className={`nav-link pointer ${
                      tabNum === 1 ? "active text-primary" : ""
                    }`}
                  >
                    RESELLERS
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    setTabNum(2);
                  }}
                >
                  <span
                    className={`nav-link pointer 
                    ${tabNum === 2 ? "active text-primary" : ""}
                    `}
                  >
                    INVITATIONS
                  </span>
                </li>

                <li
                  className="nav-item"
                  onClick={() => {
                    setTabNum(3);
                  }}
                >
                  <span
                    className={`nav-link pointer 
                    ${tabNum === 3? "active text-primary" : ""}
                    `}
                  >
                    SELLERS
                  </span>
                </li>


              </ul>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="tab-content">
                  {loading ? (
                    <div className="loading"></div>
                  ) : (
                    <div>
                      {tabNum === 1 ? (
                        <div className="tab-pane active">
                          {!resellingUsers.resellers.length ? (
                            <div
                              className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                              style={{
                                height: "200px",
                              }}
                            >
                              <span>No Resellers Found!</span>
                            </div>
                          ) : (
                            <div className="row">
                              {resellingUsers.resellers.map((obj, i) => (
                                <div
                                  className="col-12 col-md-6 col-lg-4"
                                  key={i + 1}
                                >
                                  <div className="d-flex flex-row mb-4 card">
                                    <Link
                                      className="d-flex active"
                                      to={`/admin/resellingProducts/${obj.accountId}`}
                                    >
                                      <img
                                        alt="profile"
                                        src={obj.image}
                                        className="img-thumbnail list-thumbnail align-self-center m-4  rounded-circle small"
                                      />
                                    </Link>
                                    <div className=" d-flex flex-grow-1 min-width-zero">
                                      <div className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero card-body">
                                        <Link
                                          to={`/admin/resellingProducts/${obj.accountId}`}
                                        >
                                          <div className="min-width-zero">
                                            <span className="active">
                                              <div className="truncate mb-1 card-subtitle text-black">
                                                {obj.userName}
                                              </div>
                                            </span>
                                            <p className="text-muted text-small mb-0 card-text">
                                              {obj.email}
                                            </p>

                                            <span className="iconsminds-financial fs-4 pointer text-info"></span>
                                          </div>
                                        </Link>
                                      </div>
                                      <div className="d-flex flex-column justify-content-between py-2 pr-2">
                                        {inviteLoading ? (
                                          <Spinner size={"sm"} />
                                        ) : (
                                          <span
                                            onClick={() => {
                                              setConfirmRemove(obj);
                                            }}
                                            className="simple-icon-close text-danger fs-4 pointer"
                                          ></span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ) : tabNum === 2 ?  (
                        <div className="tab-pane active">
                          {!resellingUsers.requests.length ? (
                            <div
                              className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                              style={{
                                height: "200px",
                              }}
                            >
                              <span>No Requests Found!</span>
                            </div>
                          ) : (
                            <div className="row">
                              {resellingUsers.requests.map((obj, i) => (
                                <div
                                  className="col-12 col-md-6 col-lg-4"
                                  key={i + 1}
                                >
                                  <div className="d-flex flex-row mb-4 card">
                                    <span className="d-flex active">
                                      <img
                                        alt="profile"
                                        src={obj.image}
                                        className="img-thumbnail list-thumbnail align-self-center m-4  rounded-circle small"
                                      />
                                    </span>
                                    <div className=" d-flex flex-grow-1 min-width-zero">
                                      <div className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero card-body">
                                        <div className="min-width-zero">
                                          <span className="active">
                                            <div className="truncate mb-1 card-subtitle">
                                              {obj.userName}
                                            </div>
                                          </span>
                                          <p className="text-muted text-small mb-2 card-text">
                                            {obj.email}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" d-flex flex-column justify-content-between py-2 pr-2">
                                      {inviteLoading ? (
                                        <Spinner size={"sm"} />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            handleConfirmInvite(
                                              true,
                                              obj.userId
                                            );
                                          }}
                                          className="simple-icon-check text-success fs-4 pointer"
                                        ></span>
                                      )}
                                      {inviteLoading ? (
                                        <Spinner size={"sm"} />
                                      ) : (
                                        <span
                                          onClick={() => {
                                            handleConfirmInvite(
                                              false,
                                              obj.userId
                                            );
                                          }}
                                          className="simple-icon-close text-danger fs-4 pointer"
                                        ></span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ):
                      (
                        <div className="tab-pane active">
                        {!resellingUsers.sellers.length ? (
                          <div
                            className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                            style={{
                              height: "200px",
                            }}
                          >
                            <span>No Sellers Found!</span>
                          </div>
                        ) : (
                          <div className="row">
                            {resellingUsers.sellers.map((obj, i) => (
                              <div
                                className="col-12 col-md-6 col-lg-4"
                                key={i + 1}
                              >
                                <div className="d-flex flex-row mb-4 card">
                                  <Link
                                    className="d-flex active"
                                    to={`/admin/resellingProducts/${obj.accountId}`}
                                  >
                                    <img
                                      alt="profile"
                                      src={obj.image}
                                      className="img-thumbnail list-thumbnail align-self-center m-4  rounded-circle small"
                                    />
                                  </Link>
                                  <div className=" d-flex flex-grow-1 min-width-zero">
                                    <div className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero card-body">
                                      <Link
                                        to={`/admin/resellingProducts/${obj.accountId}`}
                                      >
                                        <div className="min-width-zero">
                                          <span className="active">
                                            <div className="truncate mb-1 card-subtitle text-black">
                                              {obj.userName}
                                            </div>
                                          </span>
                                          <p className="text-muted text-small mb-0 card-text">
                                            {obj.email}
                                          </p>

                                          <span className="iconsminds-financial fs-4 pointer text-info"></span>
                                        </div>
                                      </Link>
                                    </div>
                                    
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Reselling;
