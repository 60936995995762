import { useState, useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import React from "react";
import { getBillingData, updateBillingProfile } from "../../../api/auth"; // Assuming getBillingData fetches profile
import isEmpty from "validator/lib/isEmpty";
import { toast } from "react-toastify";
import {
  InfoMessage,
  WarningMessage,
  ErrorMessage,
} from "../../Messages/message.jsx";
import {
  Row,
  CardBody,
  Label,
  Form,
  FormGroup,
  Input,
  Breadcrumb,
  Button,
} from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { leftAnimate, rightAnimate } from "../../FramerMotion/FramerMotion";

const Billing_Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [taxID, setTaxID] = useState("");
  const [notes, setNotes] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const [warningMsg, setWarningMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleGetCurrentUser = useCallback(async () => {
    try {
      // Fetch billing data
      const data = await getBillingData();
      
      
      // Destructure billingProfile from response data
      const { name, phone, address, taxId, notes } = data.billingProfile || {};
      
      // Update state with fetched data, using default empty strings if fields are missing
      setName(name || "");
      setPhone(phone || "");
      setAddress(address || "");
      setTaxID(taxId || "");
      setNotes(notes || "");
    } catch (error) {
      // Show an error notification if fetching fails
      toast.error("Fetching Billing Profile Data Failed!");
    }
  }, []); // Empty dependency array means this will be called once on mount
  

  // Fetch the data when the component mounts
  useEffect(() => {
    handleGetCurrentUser();
  }, [handleGetCurrentUser]);

  // Clear notification messages
  const setNotificationMsg = () => {
    setInfoMsg("");
    setErrorMsg("");
    setWarningMsg("");
  };

  // Handle form submission to update the profile
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmpty(name)) {
      setInfoMsg("Please Enter Name");
    } else if (isEmpty(phone)) {
      setInfoMsg("Please Enter Phone Number");
    } else if (isEmpty(address)) {
      setInfoMsg("Please Enter Address");
    } else {
      setInfoMsg(""); // Clear any previous info messages
      try {
        const result = await updateBillingProfile(name.trim(), phone.trim(), address.trim(), taxID?.trim(), notes?.trim());
        console.log('Update successful:', result);
        toast.success("Billing Profile Updated Successfully!");
      } catch (error) {
        console.error('Update failed:', error);
        setErrorMsg(error.response?.data?.errorMessage || "An error occurred.");
      }
      setShowButtons(false);
    }
  };

  return (
    <>
      <main>
        <Row className="top-text pt-3 mb-5">
          <Cols xxs="12">
            <div className="d-flex justify-content-between">
              <motion.div variants={leftAnimate} initial="hidden" animate="visible">
                <h1>Billing Profile</h1>
                <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                  <ol className="breadcrumb pt-0">
                    <li className="breadcrumb-item">
                      <Link to="/admin/dashboard" style={{ color: "black" }}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Billing Profile</li>
                  </ol>
                </Breadcrumb>
              </motion.div>
              <motion.div variants={rightAnimate} initial="hidden" animate="visible">
                <Button
                  color="primary"
                  type="submit"
                  className="mx-2"
                  style={{ float: "right" }}
                  disabled={!showButtons}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </motion.div>
            </div>
          </Cols>
        </Row>

        <Form onSubmit={handleSubmit}>
          <hr />
          <div className="container">
            <Row>
              <Cols>
                <motion.div className="card p-2">
                  <CardBody>
                    {infoMsg !== "" ? InfoMessage(infoMsg) : null}
                    {warningMsg !== "" ? WarningMessage(warningMsg) : null}
                    {errorMsg !== "" ? ErrorMessage(errorMsg) : null}

                    <Row className="mb-2">
                      <Cols>
                        <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                          <Label for="name" className="mr-2"><b>Name</b></Label>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            minLength="3"
                            maxLength="50"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value.trimStart());
                              setNotificationMsg();
                              setShowButtons(true);
                            }}
                          />
                        </FormGroup>
                      </Cols>
                      <Cols>
                        <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                          <Label for="phone" className="mr-2"><b>Phone</b></Label>
                          <Input
                            id="phone"
                            name="phone"
                            type="text"
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value.trimStart());
                              setNotificationMsg();
                              setShowButtons(true);
                            }}
                          />
                        </FormGroup>
                      </Cols>
                    </Row>

                    <Row className="mb-2">
                      <Cols>
                        <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                          <Label for="address" className="mr-2"><b>Address</b></Label>
                          <Input
                            id="address"
                            name="address"
                            type="text"
                            value={address}
                            onChange={(e) => {
                              setAddress(e.target.value.trimStart());
                              setNotificationMsg();
                              setShowButtons(true);
                            }}
                          />
                        </FormGroup>
                      </Cols>
                    </Row>

                    <Row className="mb-2">
                      <Cols>
                        <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                          <Label for="taxID" className="mr-2"><b>Tax ID</b></Label>
                          <Input
                            id="taxID"
                            name="taxID"
                            type="text"
                            value={taxID}
                            onChange={(e) => {
                              setTaxID(e.target.value.trimStart());
                              setNotificationMsg();
                              setShowButtons(true);
                            }}
                          />
                        </FormGroup>
                      </Cols>
                    </Row>

                    <Row className="mb-2">
                      <Cols>
                        <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                          <Label for="notes" className="mr-2"><b>Notes</b></Label>
                          <Input
                            id="notes"
                            name="notes"
                            type="textarea"
                            value={notes}
                            onChange={(e) => {
                              setNotes(e.target.value.trimStart());
                              setNotificationMsg();
                              setShowButtons(true);
                            }}
                          />
                        </FormGroup>
                      </Cols>
                    </Row>
                  </CardBody>
                </motion.div>
              </Cols>
            </Row>
          </div>
        </Form>
      </main>
    </>
  );
};

export default Billing_Profile;
