import React, { useRef, useEffect, useState } from 'react';
import './Popover.css'; 

const Popover = ({ children, onClose }) => {
    const popoverRef = useRef(null);
    const [marginLeft, setMarginLeft] = useState('30%'); // Initial margin

    useEffect(() => {
        const handleResize = () => {
            if (popoverRef.current) {
                const popoverWidth = popoverRef.current.offsetWidth;
                const newMarginLeft = Math.max(30 - (popoverWidth / 20), 10);
                setMarginLeft(`${newMarginLeft}%`);
            }
        };
        handleResize(); 
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        document.body.classList.add('popover-open');
        
        return () => {
            document.body.classList.remove('popover-open');
        };
    }, []);

    return (
        <div
            className="popover"
            ref={popoverRef}
            style={{ marginLeft }}
        >
            <button onClick={onClose} className="close-button">x</button>
            <div>
                {children}
            </div>
        </div>
    );
};

export default Popover;
