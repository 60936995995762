import React, { useState, useEffect, useCallback, useRef } from "react";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import DataTable from "./OrderTable";
import SpinnerButton from "../../common/SpinnerButton";
import {
  Row,
  Spinner,
  Button,
  Alert,
  Input,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import {
  updateOrdersCouriers,
  getOrderByDispatchStatus,
  addTrackingNumbers,
  updateOrderDispatchStatus,
} from "../../../api/orders";
import { ExportToCsv } from "export-to-csv";
import { toPostExFormat } from "../../../helpers/courierFileFormats/PostEx";
import { toLeopardFormat } from "../../../helpers/courierFileFormats/Leopard";
import { toSwyftFormat } from "../../../helpers/courierFileFormats/Swyft";
import { toMPFormat } from "../../../helpers/courierFileFormats/M&P";
import { toTPLFormat } from "../../../helpers/courierFileFormats/TPL";
import { getUserPreferences } from "../../../api/courier";
import { sortByDate, DateFormatted } from "../../../helpers/dateTime";
import Select from "react-select";
// import {
//   ConsigneeDetails,
//   NotePopup,
//   OrdersDetailsPopup,
// } from "../../Modals/Popups";
import { formatPhoneNumber } from "../../../helpers/phoneNumber";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getCourierOrdersObject,
  removeInvalidShipmentTypeOrders,
  validateCourierShipmentTypes,
} from "./helpers/confirmedOrderHelpers";
import DateFilters from "../../Date/DateFilters.jsx";
import { showItemDetails } from "../orderTabs/helperFunctions/tableDetailsInfo";
import { getCookie } from "../../../helpers/cookie";
import { useHistory } from "react-router-dom";
import { getAllCitiesName, getAllStoresLoginUser } from "../../../api";
import CreatableSelect from "react-select/creatable";
import {
  saveVariants,
  updateShippingCityFromOrders,
} from "../../../api/cityTypos";
import { handleSearch } from "../../search/searchHook";
// import { rightAnimate } from "../../FramerMotion/FramerMotion";
import { popOverContent, popOverList } from "../../../popOvers/popOver";
import TableSkeleton from "../../UI/TableSkeleton";
const ConfirmedOrders = ({
  setOrderExported,
  setOrderConfirmed,
  orderConfirmed,
  // setSkeletonTab,
}) => {
  const [search, setSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [selectedOrdersInBulk, setSelectedOrdersInBulk] = useState([]);
  const [isAllOrdersChecked, setIsAllOrdersChecked] = useState(false);
  const [isBookingLoader, setIsBookingLoader] = useState(false);
  const [unfulfilledOrders, setUnfulfilledOrders] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const [couriers, setCouriers] = useState([]);
  const [citiesCourier, setCitiesCourier] = useState([]);
  const [dataDefaultCourier, setDataDefaultCourier] = useState();
  const [itemDetailsData, setItemDetailsData] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const courierDDValueRef = useRef(null);
  const shipmentOptionsDDRef = useRef(null);
  const courierAfterCityUpdate = {};
  // const [orderDetailsData, setOrderDetailsData] = useState();
  // const [noteDetailsData, setNoteDetailsData] = useState("");
  // const [dateTime, setDateTime] = useState();
  const [areSelectedOrdersProcessing, setAreSelectedOrdersProcessing] =
    useState(false);
  const [selectedDispatchStatus, setSelectedDispatchStatus] = useState(null);
  const [checkCounter, setCheckCounter] = useState(0);
  const [count, setCount] = useState(0);
  // const [consigneeDetails, setConsigneeDetails] = useState();
  // const [showResetButton, setShowResetButton] = useState(false);
  const [pageLimit, setPageLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showStoreColumn, setShowStoreColumn] = useState(false);
  const [filterByCityValue, setFilterByCityValue] = useState("");
  const [filterArray, setFilterArray] = useState([]);
  const [currentCityFilterValue, setCurrentCityFilterValue] = useState("");
  const [emptyStatus, setEmptyStatus] = useState(false);
  const [filtersLoader, setFiltersLoader] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [searchCityTrigger, setSearchCityTrigger] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [cityLabels, setCityLabels] = useState([]);

  //UpdateCity
  const [cityOrderId, setCityOrderId] = useState();
  const [disabledCity, setDisabledCity] = useState(false);
  const [editCityName, setEditCityName] = useState({});
  const [newCity, setNewCity] = useState();
  const [createOption, setCreateOption] = useState(false);

  let orderFilterOptions =
    !currentCityFilterValue && !filterByCityValue
      ? [
          { value: "incorrect", label: "All Incorrect Cities" },
          { value: "correct", label: "All Correct Cities" },
          { value: "incorrect-front", label: "Current Incorrect Cities" },
          { value: "correct-front", label: "Current Correct Cities" },
        ]
      : filterByCityValue
      ? [
          { value: "incorrect", label: "All Incorrect Cities" },
          { value: "correct", label: "All Correct Cities" },
        ]
      : [
          { value: "incorrect-front", label: "Current Incorrect Cities" },
          { value: "correct-front", label: "Current Correct Cities" },
        ];

  let statusDispatchOptions = [
    { value: "AssignOrders", label: "Assign Orders" },
    { value: "ExportOrders", label: "Export Orders" },
    { value: "manual", label: "Manual Orders" },
  ];
  const couriersShipmentTypes = [
    { value: "2", label: "next day", courier: "tpl", field: "DeliveryTypeId" },
    { value: "1", label: "delay", courier: "tpl", field: "DeliveryTypeId" },
    {
      value: "OVERNIGHT",
      label: "next day",
      courier: "leopard",
      field: "shipment_type",
    },
    {
      value: "OVERLAND",
      label: "overland",
      courier: "leopard",
      field: "shipment_type",
    },
    // {
    //   value: "Overland",
    //   label: "overland",
    //   courier: "postex",
    //   field: "shipment_type",
    // },
    {
      value: "Normal",
      label: "normal",
      courier: "postex",
      field: "shipment_type",
    },
    {
      value: "Reverse",
      label: "Reverse",
      courier: "postex",
      field: "shipment_type",
    },
    {
      value: "Replacement",
      label: "Replacement",
      courier: "postex",
      field: "shipment_type",
    },
    {
      value: "Rush",
      label: "rush",
      courier: "trax",
      field: "shipment_type",
    },
    {
      value: "Saver plus",
      label: "saver plus",
      courier: "trax",
      field: "shipment_type",
    },
    { value: "Flyer", label: "next day", courier: "swyft", field: "PACKAGING" },
  ];

  const shipmentTypeData = [
    { value: "next day", label: "Next Day", couriers: "(swyft,leopard,tpl)" },
    { value: "delay", label: "Delay", couriers: "(tpl)" },
    { value: "overland", label: "Overland", couriers: "(leopard)" },
    { value: "normal", label: "Normal", couriers: "(postex)" },
    { value: "rush", label: "Rush", couriers: "(trax)" },
    { value: "saver plus", label: "Saver plus", couriers: "(trax)" },
    {
      value: "Reverse",
      label: "Reverse",
      couriers: "(postex)",
    },
    {
      value: "Replacement",
      label: "Replacement",
      couriers: "(postex)",
    },
  ];
  const history = useHistory();
  const loadOrders = useCallback(
    async ({
      page = pageNumber,
      limit = pageLimit,
      startDateIs = startDate,
      endDateIs = endDate,
      title = search.trim().length > 2 ? search : "",
      searchByCity = citySearch.trim().length > 2 ? citySearch : "",
      filterByCity = filterByCityValue?.value || "",
    }) => {
      try {
        setEmptyStatus(false);
        const { data } = await getOrderByDispatchStatus({
          dispatchStatus: "confirm",
          isAssigned: false,
          title,
          page,
          limit,
          startDateIs,
          endDateIs,
          searchByCity,
          filterByCity,
        });
        if (data) {
          // setAllOrders(data.orders);
          setUnfulfilledOrders(data.orders);
          setFilterArray(data.orders);
          setFiltersLoader(false);
          setLoading(false);
          // setSkeletonTab(false);
          setPaginationLoading(false);
          setCount(data.count);
          setCheckboxes({});
          setCheckCounter(0);
          setSelectedOrdersInBulk([]);
        }
        if (data.count === 0) {
          setEmptyStatus(true);
        } else {
          setEmptyStatus(false);
        }
        return data.orders;
      } catch (error) {
        console.log("error found when fetch order data", error);
      }
    },
    [
      pageNumber,
      pageLimit,
      endDate,
      shipmentOptionsDDRef,
      searchTrigger,
      searchCityTrigger,
      filterByCityValue,
    ]
  );
  const totalPages = Math.ceil(count / pageLimit);

  const loadCouriers = useCallback(async () => {
    try {
      const { data } = await getUserPreferences();
      setCouriers(data.couriers);
      setCitiesCourier(data.courierCities);
      setDataDefaultCourier(data.defaultCitiesCourier);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, []);

  const loadStores = useCallback(async () => {
    try {
      const { data } = await getAllStoresLoginUser();
      const { stores } = data[0];
      stores.length > 1 && setShowStoreColumn(true);
    } catch (error) {
      toast.error("Error found when fetch stores data!");
      console.log("Error found when fetch stores data!", error);
    }
  }, []);

  // const gettingForCites = async () => {
  //   const { data } = await getOrderByDispatchStatus({
  //     dispatchStatus: 'confirm',
  //     isAssigned: false,
  //     filterByCity: 'incorrect',
  //   });
  //   if (data) {
  //     const data1 = data.orders.map((order) => order?.shipping_address?.city);
  //     setForCites(data1);
  //   }
  // };

  const loadAllCities = async () => {
    try {
      const { data } = await getAllCitiesName();

      if (data) {
        setCities(data.cities);
        setCityLabels(data.cities.map((c) => c.value));
      }
    } catch (error) {
      toast.error("Error found when fetch Cities data!");
      console.log("Error found when fetch Cities data!", error);
    }
  };

  useEffect(() => {
    // gettingForCites();
    setLoading(true);
    // setSkeletonTab(true);
    loadStores();
    loadAllCities();
    loadCouriers();
  }, []);

  useEffect(() => {
    // if (unfulfilledOrders.length === 0) {
    let token = getCookie();
    if (token) {
      loadOrders({});
    } else {
      history.push("/signIn");
      toast.error("Your Session is Expired! Login Again...");
    }

    if (orderConfirmed) {
      setOrderConfirmed(false);
    }
    // if (couriers.length <= 0) {
    // }
  }, [loadOrders]);

  const getCurrentCityCourier = (destinationCity = " ") => {
    destinationCity = destinationCity.toLowerCase().trim();
    let selectedCourier = null;
    for (const courierOfCities in citiesCourier) {
      const cities = citiesCourier[courierOfCities];
      const cityMatched = cities.some(
        (city) => city.toLowerCase().trim() === destinationCity
      );
      if (cityMatched) {
        selectedCourier = courierOfCities;
      }
    }

    if (dataDefaultCourier && !selectedCourier) {
      selectedCourier = Object.keys(dataDefaultCourier)[0];
    }
    return selectedCourier;
  };

  const [countShipment, setCountShipment] = useState(0);
 let [courierSelected, setCourierSelected] = useState("");
  const [shipmentOptions, setShipmentOptions] = useState(shipmentTypeData);

  const handleCourierChange = (e, orderData) => {
    setCountShipment(countShipment+1);
    e.preventDefault();
  const sCourier = e.target.value;
  setCourierSelected(sCourier);
  const filteredShipmentOptions = shipmentTypeData.filter((option) =>
    option.couriers.toLowerCase().includes(sCourier.toLowerCase())
  );

  setShipmentOptions(filteredShipmentOptions);
    const ordersWitCourierUpdated = selectedOrdersInBulk.map(
      (selectedOrder) => {
        if (selectedOrder.orderId === orderData.orderId) {
          return { ...selectedOrder, courier: courierSelected };
        }
        return selectedOrder;
      }
    );
    setSelectedOrdersInBulk([...ordersWitCourierUpdated]);
  };

  const handleShipmentTypeChange = (e, orderData) => {
    e.preventDefault();
    const selectedShipmentType = e.target.value;

    const ordersWitShipmentTypeUpdated = selectedOrdersInBulk.map(
      (selectedOrder) => {
        if (selectedOrder.orderId === orderData.orderId) {
          return { ...selectedOrder, shipmentType: selectedShipmentType };
        }
        return selectedOrder;
      }
    );
    setSelectedOrdersInBulk([...ordersWitShipmentTypeUpdated]);
  };

  // const noteDetails = (note) => {
  //   let noteText = note.slice(0, 20);
  //   let noteLenght = note.length;
  //   return (
  //     <div>
  //       {`${noteText}${noteLenght > 20 ? "..." : ""}`}

  //       {noteLenght > 20 ? (
  //         <i
  //           style={{ cursor: "pointer" }}
  //           className="p-2 simple-icon-info "
  //           id="noteDetailPopover"
  //           onMouseEnter={() => {
  //             setNoteDetailsData(note);
  //           }}
  //         />
  //       ) : (
  //         ""
  //       )}
  //       <NotePopup noteData={noteDetailsData && noteDetailsData} />
  //     </div>
  //   );
  // };

  const onCheckItem = (event, orderId, orderData = {}) => {
    const { key: orderCourier } = orderData?.courier?.props?.children.find(
      (courier) => courier.props.selected
    );

    let shipmentOption =
      shipmentOptionsDDRef?.current?.value === undefined
        ? "next day"
        : shipmentOptionsDDRef?.current?.value;

    if (orderCourier === "PostEx") {
      shipmentOption =
        Number(orderData?.orderWeight / 1000) > 3 ? "overland" : "normal";
    } else if (orderCourier === "Trax") {
      shipmentOption =
        Number(orderData?.orderWeight / 1000) >= 5 ? "rush" : "saver plus";
    }

    setCheckboxes({ ...checkboxes, [orderId]: event.target.checked });
    let checkedOrders = [];

    unfulfilledOrders.forEach((order) => {
      if (order.orderId === orderId) {
        order.courier
          ? checkedOrders.push(order)
          : checkedOrders.push({
              ...order,
              courier: orderCourier,
              shipmentType: shipmentOption,
            });
      }
    });

    if (event.target.checked) {
      setSelectedOrdersInBulk([...selectedOrdersInBulk, ...checkedOrders]);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        const removeUnCheckedOrder = selectedOrdersInBulk.filter(
          (order) => order.orderId !== orderId
        );
        setSelectedOrdersInBulk(removeUnCheckedOrder);
      }
    }
    if (event.target.checked && orderId) {
      setCheckCounter(checkCounter + 1);
    } else if (!event.target.checked) {
      setCheckCounter(checkCounter - 1);
    }
  };

  const resetFilters = async () => {
    setFiltersLoader(true);
    setStartDate(null);
    setEndDate(null);
  };

  const onCheckAllOrders = (e, ordersToMark) => {
    setIsAllOrdersChecked(e.target.checked);
    const orderIdsToMark = {};
    const ordersCompleteData = [];
    let shipmentOption =
      shipmentOptionsDDRef?.current?.value === undefined
        ? "next day"
        : shipmentOptionsDDRef?.current?.value;

    ordersToMark.forEach((order) => {
      orderIdsToMark[order.id] = e.target.checked;
      const { key: courier } = order.courier.props.children.find(
        (element) => element.props.selected
      );

      const matchedOrderInAllOrders = unfulfilledOrders.find(
        (completeOrder) => {
          if (completeOrder.orderId === order.id) {
            completeOrder.courier = courier;
            if (completeOrder.courier === "PostEx") {
              shipmentOption =
                Number(completeOrder?.total_weight / 1000) > 3
                  ? "overland"
                  : "normal";
            } else if (completeOrder.courier === "Trax") {
              shipmentOption =
                Number(completeOrder?.total_weight / 1000) >= 5
                  ? "rush"
                  : "saver plus";
            }
            completeOrder.shipmentType = shipmentOption;
            return { ...completeOrder };
          }
          return false;
        }
      );
      ordersCompleteData.push({ ...matchedOrderInAllOrders });
    });

    if (e.target.checked) {
      setCheckboxes(orderIdsToMark);
      setSelectedOrdersInBulk(ordersCompleteData);
      setCheckCounter(ordersCompleteData.length);
    } else {
      if (selectedOrdersInBulk.length > 0) {
        setCheckboxes({});
        setSelectedOrdersInBulk([]);
        setCheckCounter(selectedOrdersInBulk.length);
      }
    }
    if (!e.target.checked) {
      return setCheckCounter(0);
    }
  };

  // const handlePostexShipType = async () => {
  //   let format = [];
  //   selectedOrdersInBulk.filter((obj) => {
  //     if (obj.courier === "PostEx") {
  //       if (Number(obj.total_weight / 1000) > 3) {
  //         obj = { ...obj, shipmentType: "overland" };
  //       } else {
  //         obj = { ...obj, shipmentType: "normal" };
  //       }
  //     }
  //     format.push(obj);
  //     return obj;
  //   });
  //   return format;
  // };

  const handleSelectedOrders = async () => {
    let bulkSelectedOrders = selectedOrdersInBulk;

    bulkSelectedOrders = [...bulkSelectedOrders];
    setAreSelectedOrdersProcessing(true);

    let { ordersWithoutShipmentType } = validateCourierShipmentTypes(
      bulkSelectedOrders,
      couriersShipmentTypes
    );

    bulkSelectedOrders = removeInvalidShipmentTypeOrders(
      bulkSelectedOrders,
      ordersWithoutShipmentType
    );
    let selectedOrdersForExport = getCourierOrdersObject(bulkSelectedOrders);

    try {
      const { data: accountPreferences } = await getUserPreferences();
      const courierApis = accountPreferences.couriersApiKeys;

      if (Object.keys(checkboxes).length > 0) {
        for (const courier in selectedOrdersForExport) {
          const hasCourierAPI = courierApis.some(
            (apiCourier) =>
              apiCourier.name.toLowerCase() === courier.toLowerCase() &&
              apiCourier.hasApiKey
          );
          setIsBookingLoader(true);
          if (!hasCourierAPI) {
            await handleExport({ [courier]: selectedOrdersForExport[courier] });
          } else {
            let fulfilledOrderIds = [];
            let fulfilledOrderRefNumbers = [];
            selectedOrdersForExport[courier].forEach(async (order) => {
              const orderId = order.orderId;
              const storeId = order.storeId;
              const shipmentType = order.shipmentType;

              const { data: orderFulfillmentResult } = await addTrackingNumbers(
                {
                  shipmentType,
                  orderId,
                  storeId,
                  trackingCompany: courier,
                }
              ).catch((error) => {
                setIsBookingLoader(false);
                toast.warning(`While add tracking: ${error.toString()}`);
              });
              if (
                orderFulfillmentResult &&
                Object.keys(orderFulfillmentResult).length > 0
              ) {
                fulfilledOrderIds.push(order.orderId);
                fulfilledOrderRefNumbers.push(
                  order.orderRefNumber.slice(
                    0,
                    order.orderRefNumber.indexOf("-")
                  )
                );
                const unAssignedOrders = unfulfilledOrders.filter(
                  (unfulfilledOrder) => {
                    return !fulfilledOrderIds.includes(
                      unfulfilledOrder.orderId
                    );
                  }
                );
                const assignedOrders = [{ id: orderId, courier }];
                await updateOrdersCouriers(assignedOrders).catch((error) =>
                  toast.warning(
                    `While updateOrdersCourier: ${error.toString()}`
                  )
                );

                const { status } = await updateOrderDispatchStatus(
                  [orderId],
                  ["assigned"],
                  [storeId]
                ).catch((error) =>
                  toast.warning(
                    `While updateDispatchStatus: ${error.toString()}`
                  )
                );
                if (status === 200) {
                  setOrderExported(true);
                }
                setUnfulfilledOrders(unAssignedOrders);
                toast.success(
                  `Successfully add tracking for store ${order.shipperDetail[0].shopDetail.name} order#${order.order_number}`
                );
              }
            });
          }
        }

        setSelectedOrdersInBulk([]);
        setCheckboxes({});
        setCheckCounter(0);
        setSelectedDispatchStatus(null);
        setAreSelectedOrdersProcessing(false);
        setIsAllOrdersChecked(false);
        setIsBookingLoader(false);
      } else {
        toast.info("please select orders before assigning couriers");
      }
    } catch (error) {
      toast.error(error?.toString());
      console.log(error);
    }
  };

  const handleExport = async (orderToExport) => {
    const exportedOrders = [];
    let assignedOrderIds = [];
    let selectedOrdersForExport = {};

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    if (Object.keys(checkboxes).length === 0) {
      toast.info("Please select orders before downloading file!");
    }

    selectedOrdersForExport = orderToExport;

    if (
      Object.keys(checkboxes).length > 0 &&
      selectedOrdersForExport &&
      selectedOrdersForExport.PostEx &&
      Object.values(selectedOrdersForExport).length > 0
    ) {
      const markedPostExOrders = selectedOrdersForExport.PostEx.filter(
        (order) => checkboxes.hasOwnProperty(order.orderId)
      );

      const postExFormatOrders = toPostExFormat(markedPostExOrders);
      const postExOrders = markedPostExOrders.map((order) => {
        assignedOrderIds.push(order.orderId);

        return {
          courier: "PostEx",
          shipmentType: order.shipmentType,
          id: order.orderId,
          name:
            order.shipping_address && order.shipping_address.name
              ? order.shipping_address.name
              : "N/A",
          address: order.shipping_address
            ? order.shipping_address.address1
            : "N/A",
          phone: order.shipping_address
            ? `+${order.shipping_address.phone}`
            : "N/A",
          weight: order.total_weight,
          amount: order.total_price_set.shop_money.amount
            ? Number(order.total_price_set.shop_money.amount).toLocaleString()
            : "N/A",
          referenceNo: order.name ? order.name : "N/A",
          details: order.line_items.map(
            (item) => `${item.title}(${item.quantity}) `
          ),
          remarks: order.note ? order.note : "none",
          orderId: order.name ? order.name : "N/A",
          city: order.shipping_address ? order.shipping_address.city : "N/A",
          storeName: order.shipperDetail[0].shopDetail.name,
          storeId: order.storeId,
        };
      });
      const postExOptions = {
        filename: `PostEx-Orders-${Date.now()}`,
        ...options,
      };

      const csvExporter = new ExportToCsv(postExOptions);
      csvExporter.generateCsv(postExFormatOrders);
      exportedOrders.push(...postExOrders);

      delete selectedOrdersForExport["PostEx"];
    }

    if (
      Object.keys(checkboxes).length > 0 &&
      selectedOrdersForExport &&
      selectedOrdersForExport.Swyft &&
      Object.values(selectedOrdersForExport).length > 0
    ) {
      const markedSwyftOrders = selectedOrdersForExport.Swyft.filter((order) =>
        checkboxes.hasOwnProperty(order.orderId)
      );
      const swyftFormatOrders = await toSwyftFormat(markedSwyftOrders);

      const swyftOrders = markedSwyftOrders.map((order) => {
        assignedOrderIds.push(order.orderId);

        return {
          courier: "Swyft",
          shipmentType: order.shipmentType,
          id: order.orderId,
          name:
            order.shipping_address && order.shipping_address.name
              ? order.shipping_address.name
              : "N/A",
          address: order.shipping_address
            ? order.shipping_address.address1
            : "N/A",
          phone: order.shipping_address
            ? `+${order.shipping_address.phone}`
            : "N/A",
          weight: order.total_weight,
          amount: order.total_price_set.shop_money.amount
            ? Number(order.total_price_set.shop_money.amount).toLocaleString()
            : "N/A",
          referenceNo: order.name ? order.name : "N/A",
          details: order.line_items.map(
            (item) => `${item.title}(${item.quantity}) `
          ),
          remarks: order.note ? order.note : "none",
          orderId: order.name,
          city: order.shipping_address ? order.shipping_address.city : "N/A",
          storeId: order.storeId,
        };
      });

      const swyftOptions = {
        filename: `Swyft-Orders-${Date.now()}`,
        ...options,
      };
      const csvExporter = new ExportToCsv(swyftOptions);

      csvExporter.generateCsv(swyftFormatOrders);
      exportedOrders.push(...swyftOrders);

      delete selectedOrdersForExport["Swyft"];
    }

    if (
      Object.keys(checkboxes).length > 0 &&
      selectedOrdersForExport &&
      selectedOrdersForExport.Leopard &&
      Object.values(selectedOrdersForExport).length > 0
    ) {
      const markedLeopardOrders = selectedOrdersForExport.Leopard.filter(
        (order) => checkboxes.hasOwnProperty(order.orderId)
      );

      const leopardFormatOrders = toLeopardFormat(markedLeopardOrders);
      const leopardOrders = markedLeopardOrders.map((order) => {
        assignedOrderIds.push(order.orderId);

        return {
          courier: "Leopard",
          shipmentType: order.shipmentType,
          id: order.orderId,
          name:
            order.shipping_address && order.shipping_address.name
              ? order.shipping_address.name
              : "N/A",
          address: order.shipping_address
            ? order.shipping_address.address1
            : "N/A",
          phone: order.shipping_address
            ? `+${order.shipping_address.phone}`
            : "N/A",
          weight: order.total_weight,
          amount: order.total_price_set.shop_money.amount
            ? Number(order.total_price_set.shop_money.amount).toLocaleString()
            : "N/A",
          referenceNo: order.name ? order.name : "N/A",
          details: order.line_items.map(
            (item) => `${item.title}(${item.quantity}) `
          ),
          remarks: order.note ? order.note : "none",
          orderId: order.name,
          city: order.shipping_address ? order.shipping_address.city : "N/A",
          storeId: order.storeId,
        };
      });

      const leopardOptions = {
        filename: `Leopard-Orders-${Date.now()}`,
        ...options,
      };
      const csvExporter = new ExportToCsv(leopardOptions);
      csvExporter.generateCsv(leopardFormatOrders);
      exportedOrders.push(...leopardOrders);

      delete selectedOrdersForExport["Leopard"];
    }

    if (
      Object.keys(checkboxes).length > 0 &&
      selectedOrdersForExport &&
      selectedOrdersForExport["M&P"] &&
      Object.values(selectedOrdersForExport).length > 0
    ) {
      const markedMPOrders = selectedOrdersForExport["M&P"].filter((order) =>
        checkboxes.hasOwnProperty(order.orderId)
      );

      const MPFormatOrders = toMPFormat(markedMPOrders);
      const mpOrders = markedMPOrders.map((order) => {
        assignedOrderIds.push(order.orderId);

        return {
          courier: "M&P",
          shipmentType: order.shipmentType,
          id: order.orderId,
          name:
            order.shipping_address && order.shipping_address.name
              ? order.shipping_address.name
              : "N/A",
          address: order.shipping_address
            ? order.shipping_address.address1
            : "N/A",
          phone: order.shipping_address
            ? `+${order.shipping_address.phone}`
            : "N/A",
          weight: order.total_weight,
          amount: order.total_price_set.shop_money.amount
            ? Number(order.total_price_set.shop_money.amount).toLocaleString()
            : "N/A",
          referenceNo: order.name ? order.name : "N/A",
          details: order.line_items.map(
            (item) => `${item.title}(${item.quantity}) `
          ),
          remarks: order.note ? order.note : "none",
          orderId: order.name,
          city: order.shipping_address
            ? cityName(order.shipping_address.city)
            : "N/A",
          items:
            order.line_items && order.line_items.length > 0
              ? showItemDetails(
                  order.line_items,
                  setItemDetailsData,
                  itemDetailsData
                )
              : "N/A",
          storeId: order.storeId,
        };
      });

      const mpOptions = {
        filename: `M&P-Orders-${Date.now()}`,
        ...options,
      };
      const csvExporter = new ExportToCsv(mpOptions);
      csvExporter.generateCsv(MPFormatOrders);
      exportedOrders.push(...mpOrders);

      delete selectedOrdersForExport["M&P"];
    }

    if (
      Object.keys(checkboxes).length > 0 &&
      selectedOrdersForExport &&
      selectedOrdersForExport["TPL"] &&
      Object.values(selectedOrdersForExport).length > 0
    ) {
      const markedTPLOrders = selectedOrdersForExport["TPL"].filter((order) =>
        checkboxes.hasOwnProperty(order.orderId)
      );
      const TPLFormatOrders = toTPLFormat(markedTPLOrders);
      const tplOrders = markedTPLOrders.map((order) => {
        assignedOrderIds.push(order.orderId);

        return {
          courier: "TPL",
          shipmentType: order.shipmentType,
          id: order.orderId,
          name:
            order.shipping_address && order.shipping_address.name
              ? order.shipping_address.name
              : "N/A",
          address: order.shipping_address
            ? order.shipping_address.address1
            : "N/A",
          phone: order.shipping_address
            ? `+${order.shipping_address.phone}`
            : "N/A",
          weight: order.total_weight,
          amount: order.total_price_set.shop_money.amount
            ? Number(order.total_price_set.shop_money.amount).toLocaleString()
            : "N/A",
          referenceNo: order.name ? order.name : "N/A",
          details: order.line_items.map(
            (item) => `${item.title}(${item.quantity}) `
          ),
          remarks: order.note ? order.note : "none",
          orderId: order.name,
          city: order.shipping_address ? (
            <span
              style={
                cityLabels.includes(
                  order?.shipping_address?.city?.toLowerCase()
                )
                  ? {}
                  : { color: "red" }
              }
            >
              {order.shipping_address.city}
            </span>
          ) : (
            "N/A"
          ),
          items:
            order.line_items && order.line_items.length > 0
              ? showItemDetails(
                  order.line_items,
                  setItemDetailsData,
                  itemDetailsData
                )
              : "N/A",
          storeId: order.storeId,
        };
      });

      const tplOptions = {
        filename: `TPL-Orders-${Date.now()}`,
        ...options,
      };
      const csvExporter = new ExportToCsv(tplOptions);
      csvExporter.generateCsv(TPLFormatOrders);
      exportedOrders.push(...tplOrders);

      delete selectedOrdersForExport["TPL"];
    }

    if (exportedOrders.length > 0) {
      setCheckboxes({});
      setIsAllOrdersChecked(false);

      try {
        await updateOrdersCouriers(
          exportedOrders.map((order) => ({
            id: order.id,
            courier: order.courier,
          }))
        );
        let successOrders = [];
        for (const order of exportedOrders) {
          const { status } = await updateOrderDispatchStatus(
            [order.id],
            ["unAssigned"],
            [order.storeId]
          ).catch((error) => toast.warning(error.toString()));
          if (status === 200) {
            successOrders.push(order.orderId);
          }
        }
        successOrders.length > 0 &&
          toast.success(
            `File Exported Successfully for Order ${String(successOrders)}`
          );
        const remainingConfirmedOrders = unfulfilledOrders.filter(
          (unfulfilledOrder) =>
            !assignedOrderIds.includes(unfulfilledOrder.orderId)
        );
        setUnfulfilledOrders(remainingConfirmedOrders);

        setOrderExported(true);

        return true;
      } catch (e) {
        toast.warning(`${e.message}`);
      }
    }
    setCheckboxes({});

    return false;
  };

  const handleCurrentCityFilter = () => {
    filterByCityValue && setFilterByCityValue("");
    const sorting = currentCityFilterValue;
    if (sorting?.value) {
      sorting?.value === "correct-front"
        ? setUnfulfilledOrders(
            filterArray.filter((obj) =>
              cityLabels.includes(obj.shipping_address?.city?.toLowerCase())
            )
          )
        : setUnfulfilledOrders(
            filterArray.filter(
              (obj) =>
                !cityLabels.includes(obj.shipping_address?.city?.toLowerCase())
            )
          );
    } else {
      setUnfulfilledOrders(filterArray);
    }
  };

  useEffect(() => {
    if (!filterByCityValue) {
      return handleCurrentCityFilter();
    }
  }, [filterArray, currentCityFilterValue]);

  const handleAllCityFilter = async (e) => {
    currentCityFilterValue && setCurrentCityFilterValue("");
    setFilterByCityValue(e);

    setPageNumber(1);
    setPageLimit(50);
    setFiltersLoader(true);
  };

  const handleOrderDispatchToManual = async () => {
    if (selectedOrdersInBulk.length > 0) {
      if (selectedDispatchStatus) {
        let orderIds = [];
        let storeIds = [];
        let dispatchStatus = [];
        selectedOrdersInBulk.map((s) => {
          orderIds.push(s.orderId);
          storeIds.push(s.storeId);
          dispatchStatus.push("manual");
          return s;
        });

        try {
          setAreSelectedOrdersProcessing(true);

          const dispatchToManualResult = await updateOrderDispatchStatus(
            [...orderIds],
            [...dispatchStatus],
            [...storeIds]
          );

          if (dispatchToManualResult.status === 200) {
            let dispatchedOrders = selectedOrdersInBulk.map((o) => o.name);
            toast.success(`Manual Successfully (${String(dispatchedOrders)})`);
            setSelectedOrdersInBulk([]);
            await loadOrders({});
            setAreSelectedOrdersProcessing(false);
          }
          setCheckboxes({});
          setCheckCounter(0);
        } catch (error) {
          console.log(error);
          setAreSelectedOrdersProcessing(false);

          toast.error("Error while Manual orders");
        }
      } else {
        toast.info("Please select action");
      }
    } else {
      toast.info("Please select orders");
    }
  };

  const handleExportOrdersBtn = async () => {
    setAreSelectedOrdersProcessing(true);
    let bulkSelectedOrders = selectedOrdersInBulk;
    let { ordersWithoutShipmentType } = validateCourierShipmentTypes(
      bulkSelectedOrders,
      couriersShipmentTypes
    );

    bulkSelectedOrders = removeInvalidShipmentTypeOrders(
      bulkSelectedOrders,
      ordersWithoutShipmentType
    );
    let selectedOrdersForExport = getCourierOrdersObject(bulkSelectedOrders);

    if (Object.keys(checkboxes).length > 0) {
      await handleExport(selectedOrdersForExport);
      setSelectedOrdersInBulk([]);
      setCheckboxes({});
      setCheckCounter(0);
      setSelectedDispatchStatus(null);
      setIsAllOrdersChecked(false);
      setAreSelectedOrdersProcessing(false);
    } else {
      toast.info("Please select orders before assigning couriers");
    }
  };

  // const consigneeDetail = (item, i) => {
  //   let orderItems = item.slice(0, 20);
  //   let itemsLength = item.length;
  //   let hoverIndex;
  //   return (
  //     <div>
  //       {orderItems}
  //       {itemsLength > 20 && (
  //         <>
  //           {" ... "}
  //           <i
  //             className="simple-icon-info pointer"
  //             id={"consigneeDetailPopover" + hoverIndex}
  //             onMouseEnter={() => {
  //               hoverIndex = i;
  //               setConsigneeDetails([item]);
  //             }}
  //             onMouseLeave={() => {
  //               hoverIndex = undefined;
  //               setConsigneeDetails();
  //             }}
  //           />
  //         </>
  //       )}
  //       {consigneeDetails && (
  //         <ConsigneeDetails
  //           consigneeDetails={consigneeDetails}
  //           index={hoverIndex}
  //         />
  //       )}
  //     </div>
  //   );
  // };

  // const orderDetails = (items) => {
  //   let orderItems = items[0].title.slice(0, 12);
  //   let itemsLength = items[0].title.length;
  //   return (
  //     <>
  //       <div>
  //         {`${orderItems}${itemsLength > 12 ? "...." : ""}`}
  //         {itemsLength > 12 ? (
  //           <i
  //             style={{ cursor: "pointer" }}
  //             className="p-2 simple-icon-info "
  //             id="orderDetailPopover"
  //             onMouseEnter={() => {
  //               setOrderDetailsData(items);
  //             }}
  //           />
  //         ) : (
  //           ""
  //         )}
  //         <OrdersDetailsPopup
  //           orderDetailsData={orderDetailsData && orderDetailsData}
  //         />
  //       </div>
  //     </>
  //   );
  // };

  const cityName = (cityName) => {
    let value = cityName ? cityName : "N/A";
    return (
      <span
        className={
          cityLabels.includes(value?.toLowerCase()) ? "" : "text-danger"
        }
      >
        {value}
      </span>
    );
  };

  const handleChangeCity = (e, cityName) => {
    if (e.key === "Enter") {
      setValue(cityName);
    }
  };

  const handleFindCity = async (checkNewCity) => {
    setNewCity(checkNewCity);
    let checkCityInDB = await cities.find(
      (checkCity) => checkCity.label === checkNewCity
    );
    if (!checkCityInDB) {
      setCreateOption(true);
    } else {
      setCreateOption(false);
    }
  };

  const setValue = async (cityName) => {
    if (newCity) {
      setDisabledCity(true);
      try {
        if (createOption === true) {
          await updateShippingCityFromOrders(newCity, cityOrderId);
          setCreateOption(false);
        } else {
          await saveVariants(newCity, cityName, cityOrderId);
        }
        setEditCityName({ orderId: cityOrderId, cityName: newCity });
        setCityOrderId();
        setNewCity();
        setDisabledCity(false);
        // const selectedCourier = getCurrentCityCourier(newCity);
        // setCourierAfterCityUpdate({
        //   orderId: cityOrderId,
        //   courier: selectedCourier,
        // });
        // const shipmentOption = shipmentOptionsDDRef?.current?.value;

        // const updatedCourierUnfulfilledInOrders = unfulfilledOrders.map(
        //   (order) => {
        //     if (order.orderId === cityOrderId) {
        //       return {
        //         ...order,
        //         courier: selectedCourier,
        //         shipmentType: order.shipmentType
        //           ? order.shipmentType
        //           : shipmentOption,
        //         shipping_address: { ...order.shipping_address, city: newCity },
        //       };
        //     }
        //     return order;
        //   }
        // );

        // setUnfulfilledOrders(updatedCourierUnfulfilledInOrders);

        // if (selectedOrdersInBulk.length > 0) {
        //   const updatedCourierInSelectedOrders = selectedOrdersInBulk.map(
        //     (selectedOrder) => {
        //       if (selectedOrder.orderId === cityOrderId) {
        //         return {
        //           ...selectedOrder,
        //           courier: selectedCourier,
        //           shipmentType: selectedOrder.shipmentType
        //             ? selectedOrder.shipmentType
        //             : shipmentOption,
        //           shipping_address: {
        //             ...selectedOrder.shipping_address,
        //             city: newCity,
        //           },
        //         };
        //       }
        //       return selectedOrder;
        //     }
        //   );
        //   setSelectedOrdersInBulk(updatedCourierInSelectedOrders);
        // }

        toast.success("Destination City Updated Successfully!");
      } catch (error) {
        setCityOrderId();
        setDisabledCity(false);
        toast.error(error.toString());
      }
    } else {
      toast.error("City Name Required!");
    }
  };

  const cancelEditCityMenu = () => {
    setCityOrderId();
    setNewCity();
    setCreateOption(false);
  };

  const ChangeCityName = (cityName, orderId) => {
    if (orderId === cityOrderId) {
      return (
        <>
          {disabledCity === false ? (
            <>
              <CreatableSelect
                key={orderId}
                options={cities}
                formatCreateLabel={() => "This is Correct"}
                defaultValue={{
                  label: cityName,
                  value: cityName.toLowerCase(),
                }}
                onKeyDown={() => handleChangeCity(cityName)}
                onChange={(e) => {
                  handleFindCity(e.label);
                }}
              />
              <Button
                onClick={() => {
                  setValue(newCity);
                }}
                color="primary"
                size="xs"
                className="mr-2 mt-1"
                hidden={createOption === false}
              >
                Add Anyway
              </Button>
              <Button
                onClick={() => setValue(cityName)}
                className="mr-2 mt-1"
                color="primary"
                size="xs"
                hidden={createOption === true}
              >
                Select
              </Button>
              <label
                onClick={cancelEditCityMenu}
                style={{ color: "red", cursor: "pointer" }}
                className="mt-1"
              >
                Cancel
              </label>
            </>
          ) : (
            <>
              <Select
                key={orderId}
                options={cities}
                isDisabled
                menuPosition="absolute"
                menuPlacement="auto"
              />
              <Spinner size="sm" color="primary" />
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {Object.keys(editCityName).length > 0 &&
          editCityName.orderId === orderId ? (
            <span
              className={
                cityLabels?.includes(editCityName.cityName?.toLowerCase())
                  ? ""
                  : "text-danger"
              }
            >
              {popOverContent(`City-${orderId}`, editCityName.cityName)}
            </span>
          ) : (
            <span
              className={
                cityLabels?.includes(cityName?.toLowerCase())
                  ? ""
                  : "text-danger"
              }
            >
              {popOverContent(`City-${orderId}`, cityName)}
            </span>
          )}

          <span
            className="simple-icon-pencil fs-6 ms-2 pointer"
            onClick={() => {
              setCityOrderId(orderId);
              // setClickedOrderId('');
              // setClickedHeader('');
            }}
          ></span>
        </>
      );
    }
  };

  const ordersData =
    unfulfilledOrders &&
    unfulfilledOrders.length > 0 &&
    unfulfilledOrders.map((order, i) => {
      return {
        id: order.orderId,
        orderId: order.name,
        orderDate: DateFormatted(order.created_at, i),
        name: popOverContent(`Name-${i}`, order?.shipping_address?.name),

        phone: popOverContent(
          `Phone-${i}`,
          formatPhoneNumber(order?.shipping_address?.phone)
        ),
        note: popOverContent(`Note-${i}`, order.note),
        // weight: order.total_weight,
        amount: popOverContent(
          `Total-${i}`,
          Number(order.total_price_set.shop_money.amount).toLocaleString()
        ),
        // referenceNo: order.orderId,
        // details: orderDetails(order.line_items),
        // city: cityName(order?.shipping_address?.city),
        city: order?.shipping_address?.city
          ? ChangeCityName(order.shipping_address.city, order.orderId)
          : ChangeCityName("null", order.orderId),
        storeName: popOverContent(
          `Store-${i}`,
          order.shipperDetail[0]?.shopDetail.name.replace(`${order?.name}-`, "")
        ),

        items: (
          <>
            {order?.line_items.length}
            {"  "}
            {order?.line_items.length > 0
              ? popOverList(
                  `lineItem-${i}`,
                  order.line_items.map((o) => `${o.title} (${o.quantity})`)
                )
              : "N/A"}
          </>
        ),

        courier: (
          <select
            key={order.orderId}
            onChange={(e) => handleCourierChange(e, order)}
            ref={courierDDValueRef}
            className={"form-control"}
            disabled={checkboxes[order.orderId] === true ? false : true}
          >
            {couriers?.length > 0 &&
              couriers?.map((courier) => {
                courierSelected =
                  Object.keys(courierAfterCityUpdate).length > 0 &&
                  courierAfterCityUpdate.orderId === order.orderId
                    ? courierAfterCityUpdate.courier
                    : getCurrentCityCourier(
                        order.shipping_address && order.shipping_address.city
                      );
                      
                      
                return (
                  <option
                    key={courier?.label}
                    value={
                      courier?.label === courierSelected
                        ? courierSelected
                        : courier?.label
                    }
                    selected={courier?.label === courierSelected ? true : false}
                  >
                    {courier?.label === courierSelected
                      ? courierSelected
                      : courier?.label}
                  </option>
                );
              } 
            )
              }
            
                       
          </select>

        ),
        shipmentOptions: (
          <form>

<select
    onChange={(e) => handleShipmentTypeChange(e, order)}
    className="form-control"
    placeholder="Shipping Option"
    disabled={checkboxes[order.orderId] === true ? false : true}
    ref={shipmentOptionsDDRef}
    defaultValue={
      Object.keys(dataDefaultCourier)[0] === "PostEx"
        ? Number(order?.total_weight / 1000) > 3
          ? "normal"
          : "normal"
        : Object.keys(dataDefaultCourier)[0] === "Trax"
        ? Number(order?.total_weight / 1000) >= 5
          ? "rush"
          : "saver plus"
        : ""
    }
  >
  { countShipment === 0 ? shipmentOptions
  .filter((option) => {
    return courierSelected && option.couriers ? option.couriers.toLowerCase().includes(courierSelected.toLowerCase()) : false;
  })
  .map((option, index) => (
    <option key={index} value={option.value}>
      {option.label}
    </option>
  )) : shipmentOptions.map((option, index) => (
    <option key={index} value={option.value}>
      {option.label}
    </option>
  ))}
  </select>
  

          </form>
        ),
        orderWeight: order?.total_weight,
      };
    });

  const cols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-3 text-start",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "COD",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Courier",
        accessor: "courier",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Shipping Option",
        accessor: "shipmentOptions",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  const myCols = React.useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        cellClass: " w-3 text-start",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Date",
        accessor: "orderDate",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Customer",
        accessor: "name",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Phone",
        accessor: "phone",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Note",
        accessor: "note",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "COD",
        accessor: "amount",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },

      {
        Header: "Items",
        accessor: "items",
        cellClass: " w-3",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Destination City",
        accessor: "city",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Store",
        accessor: "storeName",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Courier",
        accessor: "courier",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Shipping Option",
        accessor: "shipmentOptions",
        cellClass: " w-5",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  const moreActions = (e) => {
    if (e) {
      setSelectedDispatchStatus(e.label);
    } else {
      setSelectedDispatchStatus(null);
    }
  };

  return (
    <>
      {loading ? (
        <TableSkeleton skeletonLength={10} itemsBar={3} />
      ) : emptyStatus &&
        !search.trim() &&
        !citySearch.trim() &&
        !startDate &&
        !endDate &&
        !filterByCityValue ? (
        <div
          className="d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
          style={{
            height: "200px",
          }}
        >
          <span>No Order Found!</span>
        </div>
      ) : (
        <Row>
          <Cols xxs="12">
            <div
              className="mb-4 px-2"
              // variants={rightAnimate}
              // initial="hidden"
              // animate="visible"
            >
              <div className={{ padding: "0px" }}>
                <div className="card-title mb-0">
                  <div className="d-flex justify-content-between flex-wrap mt-4 ">
                    <div className="mb-2 me-1 position-relative">
                      <Input
                        type="text"
                        name="keyword"
                        id="search"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          handleSearch({
                            e,
                            setPageNumber,
                            setPageLimit,
                            setFilterLoading: setFiltersLoader,
                            setSearch,
                            setSearchTrigger,
                            searchTrigger,
                          });
                        }}
                        className="rounded-3"
                        disabled={
                          paginationLoading ||
                          filtersLoader ||
                          selectedOrdersInBulk.length > 0
                        }
                      />
                      {search.trim() && search.trim().length < 3 && (
                        <i
                          className="simple-icon-info pointer position-absolute text-danger fw-bold"
                          style={{ right: "-20px", bottom: "14px" }}
                          id={"SearchInfo"}
                        >
                          {" "}
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target={"SearchInfo"}
                          >
                            <PopoverBody className="text-center">
                              <i>Minimum 3 words required!</i>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </i>
                      )}
                      {search &&
                        (filtersLoader && search.trim().length > 2 ? (
                          <Spinner
                            color="primary"
                            size={"sm"}
                            style={{ right: "10px", bottom: "14px" }}
                            className=" position-absolute"
                            type="grow"
                          ></Spinner>
                        ) : (
                          <span
                            className="far fa-close fs-6 position-absolute text-danger pointer"
                            style={{ right: "10px", bottom: "14px" }}
                            onClick={() => {
                              if (search) {
                                setSearch("");

                                setSearchTrigger(!searchTrigger);
                                setFiltersLoader(true);
                              }
                              pageLimit > 50 && setPageLimit(50);
                              pageNumber > 1 && setPageNumber(1);
                            }}
                          ></span>
                        ))}
                    </div>
                    <div>
                      <DateFilters
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        selectedDateFilter={selectedDateFilter}
                        setSelectedDateFilter={setSelectedDateFilter}
                        // setShowResetButton={setShowResetButton}
                        loadOrders={loadOrders}
                        resetFilters={resetFilters}
                        setFiltersLoader={setFiltersLoader}
                        paginationLoading={paginationLoading}
                        filtersLoader={filtersLoader}
                        allOrdersFlag={"allOrdersFlag"}
                        selectedOrdersInBulk={selectedOrdersInBulk}
                      />
                    </div>
                    <div className=" d-flex flex-wrap">
                      <div className="mb-2 mr-2 position-relative confirm-city-search">
                        <Input
                          type="text"
                          name="keyword"
                          id="search"
                          placeholder="Search Cities"
                          value={citySearch}
                          onChange={(e) => {
                            setEmptyStatus(false);
                            handleSearch({
                              e,
                              setPageNumber,
                              setPageLimit,
                              setFilterLoading: setFiltersLoader,
                              setSearch: setCitySearch,
                              setSearchTrigger: setSearchCityTrigger,
                              searchTrigger: searchCityTrigger,
                            });
                          }}
                          className="rounded-3"
                          disabled={
                            paginationLoading ||
                            filtersLoader ||
                            selectedOrdersInBulk.length > 0
                          }
                        />

                        {citySearch &&
                          (filtersLoader && citySearch.trim().length > 2 ? (
                            <Spinner
                              color="primary"
                              size={"sm"}
                              style={{ right: "10px", bottom: "14px" }}
                              className=" position-absolute"
                              type="grow"
                            ></Spinner>
                          ) : (
                            <span
                              className="far fa-close fs-6 position-absolute text-danger pointer"
                              style={{ right: "10px", bottom: "14px" }}
                              onClick={() => {
                                if (citySearch) {
                                  setCitySearch("");

                                  setSearchCityTrigger(!searchCityTrigger);
                                  setFiltersLoader(true);
                                }
                                pageLimit > 50 && setPageLimit(50);
                                pageNumber > 1 && setPageNumber(1);
                              }}
                            ></span>
                          ))}
                      </div>
                      {citySearch.trim() && citySearch.trim().length < 3 && (
                        <div className="d-flex align-items-center pb-2">
                          <i
                            className="simple-icon-info pointer text-danger fw-bold mr-2"
                            // style={{ left: '-20px', bottom: '14px' }}
                            id={"CitySearchInfo"}
                          >
                            {" "}
                            <UncontrolledPopover
                              trigger="hover"
                              placement="top"
                              target={"CitySearchInfo"}
                            >
                              <PopoverBody className="text-center">
                                <i>Minimum 3 words required!</i>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </i>
                        </div>
                      )}
                      <div
                        className="openOrderSelect alignY-xxs mb-2 mr-2"
                        style={{ width: "160px" }}
                      >
                        <Select
                          options={orderFilterOptions}
                          placeholder="Filter Cities"
                          hideSelectedOptions
                          value={filterByCityValue || currentCityFilterValue}
                          onChange={(e) => {
                            if (e) {
                              e?.value.includes("-front")
                                ? setCurrentCityFilterValue(e)
                                : handleAllCityFilter(e);
                            } else {
                              currentCityFilterValue
                                ? setCurrentCityFilterValue("")
                                : handleAllCityFilter("");
                            }
                          }}
                          isClearable
                          isDisabled={
                            paginationLoading ||
                            filtersLoader ||
                            selectedOrdersInBulk.length > 0
                          }
                        />
                      </div>

                      <div
                        className="openOrderSelect me-2 mb-2"
                        style={{ width: "177px" }}
                      >
                        <Select
                          placeholder="Actions"
                          value={
                            selectedDispatchStatus
                              ? selectedDispatchStatus.label
                              : ""
                          }
                          options={statusDispatchOptions}
                          isDisabled={selectedOrdersInBulk.length === 0}
                          onChange={(e) => moreActions(e)}
                          isClearable
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: "36px",
                          minWidth: "139px",
                          marginTop: "2px",
                        }}
                      >
                        {(checkCounter === 0 || !selectedDispatchStatus) &&
                        !isBookingLoader ? (
                          <button
                            disabled={
                              selectedDispatchStatus === null ||
                              selectedOrdersInBulk.length === 0 ||
                              areSelectedOrdersProcessing ||
                              isBookingLoader
                            }
                            type="button"
                            className="btn btn-primary custom-btn "
                            style={{
                              height: "36px",
                            }}
                            onClick={() => {
                              selectedDispatchStatus === null &&
                                toast.error("Please Select Action.");
                            }}
                          >
                            Submit Orders
                          </button>
                        ) : (
                          <div
                            style={
                              areSelectedOrdersProcessing
                                ? {
                                    height: "100%",
                                    width: "100%",
                                  }
                                : {
                                    height: "100%",
                                    width: "199%",
                                  }
                            }
                          >
                            <SpinnerButton
                              btnText={
                                selectedDispatchStatus === null
                                  ? "Submit Orders"
                                  : selectedDispatchStatus
                              }
                              isProcessing={
                                areSelectedOrdersProcessing || isBookingLoader
                              }
                              handler={
                                selectedDispatchStatus === "Assign Orders"
                                  ? handleSelectedOrders
                                  : selectedDispatchStatus === "Manual Orders"
                                  ? handleOrderDispatchToManual
                                  : handleExportOrdersBtn
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {paginationLoading && (
                  <div className="mt-3 mx-3">
                    <Alert color="info">
                      <Spinner
                        color="light"
                        size={"sm"}
                        style={{ marginBottom: "3px" }}
                      ></Spinner>{" "}
                      &nbsp;
                      <span style={{ fontSize: "16px", color: "black" }}>
                        Orders Loading!
                      </span>
                    </Alert>
                  </div>
                )}

                {filtersLoader ? (
                  <TableSkeleton skeletonLength={10} />
                ) : unfulfilledOrders?.length === 0 ? (
                  <div
                    className=" d-flex justify-content-center align-items-center text-danger fw-bold fs-5"
                    style={{
                      height: "200px",
                    }}
                  >
                    <span>No Order Matched!</span>
                  </div>
                ) : (
                  <DataTable
                    setPageLimitInParent={setPageLimit}
                    setPageNumberInParent={setPageNumber}
                    fetchData={loadOrders}
                    pageCount={totalPages}
                    columns={showStoreColumn ? myCols : cols}
                    data={ordersData.sort(sortByDate)}
                    onCheckItem={onCheckItem}
                    checkedOrders={checkboxes}
                    isAllOrdersChecked={isAllOrdersChecked}
                    onCheckAllOrders={onCheckAllOrders}
                    checkCounter={checkCounter}
                    loadingBool={loading}
                    selectedOrdersActionBool={areSelectedOrdersProcessing}
                    setPaginationLoading={setPaginationLoading}
                    paginationLoading={paginationLoading}
                    totalCount={count}
                  />
                )}
              </div>
            </div>
          </Cols>
        </Row>
      )}
    </>
  );
};

export default ConfirmedOrders;
