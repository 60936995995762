import React, { useState, useEffect, useCallback} from "react";
import { toast } from "react-toastify";

import { Row, Card, CardBody, CardTitle, Col } from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import icons8_mastercard from "../../../images/Billing/icons8-mastercard-60.png";
import icons8_discover_card from "../../../images/Billing/icons8-discover-card-60.png";
import icons8_american_express from "../../../images/Billing/icons8-american-express-60.png";
import icons_visa from "../../../images/Billing/icons8-visa-60.png";
import image67 from "../../../images/Billing/image67.png";
import image71 from "../../../images/Billing/image71.png";
import image72 from "../../../images/Billing/image72.png";
import Rectangle1 from "../../../images/Billing/Rectangle1.svg";
import Group_fb from "../../../images/Billing/Group_fb.svg";
import Group_whatsApp from "../../../images/Billing/Group_whatsApp.svg";
import Group_youtube from "../../../images/Billing/Group_youtube.svg";


// Getting Apis
import { getOrderBillings, getOrderNumbers } from "../../../api/billing";
import { getAllResellers } from "../../../api/reselling"; // Update with the actual path to your API functions
import { getBillingData } from '../../../api/auth'; // Adjust the import based on your project structure


const InvoiceShow = () => {
  
  let today = new Date();
  let date =
    today.getDate() +
    " " +
    today.toLocaleString("en-us", { month: "short" }) +
    " " +
    today.getFullYear();

  //Fetching Data
  const [billingData, setBillingData] = useState([]);
  const [totalFulfillmentCharge, setTotalFulfillmentCharge] = useState(0);
  const [resellingData, setResellingData] = useState([]);
  const [totalResellingCharge, setTotalResellingCharge] = useState(0);
  const [creditBalance, setCreditBalance] = useState(0);

  const [orderNumbers, setOrderNumbers] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [taxID, setTaxID] = useState("");
  const [notes, setNotes] = useState("");

  const handleGetCurrentUser = useCallback(async () => {
    try {
      const data = await getBillingData(); // Ensure this fetches billing data
      const { name, phone, address, taxId, notes } = data.billingProfile || {}; // Update based on your response structure
      setName(name || "");
      setPhone(phone || "");
      setAddress(address || "");
      setTaxID(taxId || "");
      setNotes(notes || "");
    } catch (error) {
      toast.error("Fetching Billing Profile Data Failed!");
    }
  }, []);
  useEffect(() => {
    handleGetCurrentUser();
  }, [handleGetCurrentUser]);

  // SMS for future use
  const [smsData, setSMSData] = useState({
    totalSMS: 0,
    smsUsed: 0,
    totalCost: 0,
  });

  // APP for future use
  const [appData, setAppData] = useState({
    appCount: 0, // Default to zero items
    appName: "No Apps Installed", // Placeholder name
    totalCost: 0, // Placeholder cost
    reportCost: 0, // Placeholder report cost
    message: "There Is No App On Your Account Right Now", // Placeholder message
  });
  useEffect(() => {
    // Future API call to fetch app data can be placed here
    // setAppData({ appCount: fetchedData.count, appName: fetchedData.name, ...});
  }, []);

  // Void Order for future use
  const [voidOrders, setVoidOrders] = useState(0);
  const [voidOrdersAmount, setVoidOrdersAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [affiliateEarnings, setAffiliateEarnings] = useState(0);
  const [affiliateStores, setAffiliateStores] = useState([]);
  const [grossTotal, setGrossTotal] = useState(0);
  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const res = await fetch("/api/financial-summary");
        const data = await res.json();

        // Update the state with API data
        setVoidOrders(data.voidOrders || 0);
        setVoidOrdersAmount(data.voidOrdersAmount || 0);
        setDiscount(data.discount || 0);
        setAffiliateEarnings(data.affiliateEarnings || 0);
        setAffiliateStores(data.affiliateStores || []);
        setGrossTotal(data.grossTotal || 0);
      } catch (error) {
        console.error("Error fetching financial summary:", error);
      }
    };

    fetchData();
  }, []);

  // Set startDateIs and endDateIs to August 2023
  const [startDateIs, setStartDateIs] = useState("2023-08-01");
  const [endDateIs, setEndDateIs] = useState("2023-08-31");

  // Fetch billing data
  // useEffect(() => {
  //   const fetchBillingData = async () => {
  //     try {
  //       const response = await getOrderBillings({ startDateIs, endDateIs });
  //       const data = response.data.separatStoresData;
  //       setBillingData(data);

  //       // Calculate total fulfillment charges
  //       const totalCharge = data.reduce((acc, store) => {
  //         const totalOrders = store.orders.length;
  //         const fulfillmentCharge = store.isMaster ? totalOrders * 10 : totalOrders * 1;
  //         return acc + fulfillmentCharge;
  //       }, 0);

  //       setTotalFulfillmentCharge(totalCharge);
  //     } catch (error) {
  //       console.error("Error fetching billing data:", error);
  //     }
  //   };

  //   fetchBillingData();
  // }, [startDateIs, endDateIs]);
  useEffect(() => {
    const fetchBillingData = async () => {
      try {
        const response = await getOrderBillings({ startDateIs, endDateIs });
        const data = response.data.separatStoresData;
        setBillingData(data);

        // Calculate total fulfillment charges
        const totalCharge = data.reduce((acc, store) => {
          const totalOrders = store.orders.length;
          const fulfillmentCharge = store.isMaster
            ? totalOrders * 10
            : totalOrders * 1;
          return acc + fulfillmentCharge;
        }, 0);

        setTotalFulfillmentCharge(totalCharge);

        // // Calculate total amount
        // const grossTotal = data.reduce((acc, store) => {
        //   return acc + store.orders.reduce((orderTotal, order) => orderTotal + parseFloat(order.total_price), 0);
        // }, 0);

        // setTotalAmount(grossTotal);
      } catch (error) {
        console.error("Error fetching billing data:", error);
      }
    };

    fetchBillingData();
  }, [startDateIs, endDateIs]);

  // Fetch order numbers
  useEffect(() => {
    const fetchOrderNumbers = async () => {
      try {
        const response = await getOrderNumbers();
        setOrderNumbers(response.separatStoresData);
      } catch (error) {
        console.error("Error fetching order numbers:", error);
      }
    };

    fetchOrderNumbers();
  }, []);

  useEffect(() => {
    const fetchResellingData = async () => {
      try {
        const response = await getAllResellers();
        const resellers = response.data.seller.resellers; // Adjust based on the actual response structure

        // Calculate total reselling charges
        let totalOrders = 0;
        let updatedResellers = [];

        if (resellers.length > 0) {
          updatedResellers = resellers.map((reseller) => {
            const ordersCount = reseller.ordersCount || 0; // Adjust according to how you get the number of orders
            totalOrders += ordersCount;
            return {
              ...reseller,
              ordersCount,
            };
          });
        }

        const totalCharge = totalOrders * 60; // Assuming Rs. 60 per order

        setResellingData(updatedResellers);
        setTotalResellingCharge(totalCharge);
      } catch (error) {
        console.error("Error fetching reselling data:", error);
      }
    };

    fetchResellingData();
  }, []);

  // SMS DATA
  // useEffect(() => {
  //   const fetchSMSData = async () => {
  //     try {
  //       const response = await getSMSData();
  //       // Update the smsData based on the response
  //       const { totalSMS, smsUsed, totalCost } = response.data; // Adjust based on the actual response structure
  //       setSMSData({ totalSMS, smsUsed, totalCost });
  //     } catch (error) {
  //       console.error("Error fetching SMS data:", error);
  //     }
  //   };

  //Load Bill at First Render
  // const loadBill = useCallback(async () => {
  //   try {
  //     const billData = await getCurrentBillingData();
  //     setBills(billData.data);
  //     setUSD(billData.data.filter((data) => data.multiplier === '0.1 USD'));
  //     setPercent(billData.data.filter((data) => data.multiplier === '0.5%'));
  //   } catch (error) {
  //     console.log('error found when fetch order data', error);
  //   }
  // }, []);

  // useEffect(() => {
  //   loadBill();
  // }, [loadBill]);



  //Total and subtotal
  const subTotal = totalFulfillmentCharge + totalResellingCharge + appData.totalCost + smsData.totalCost - affiliateEarnings - creditBalance;

// Calculate GST (10% of subtotal)
const gst = subTotal * 0.10;

// Calculate total
const total = subTotal + gst;
  return (
    <>
      <main>
        <Row>
          <Cols xxs="12" className="row icon-cards-row mb-2">
            <Cols xs="12" sm="12" className="mb-4 ">
              <Card>
                <CardBody className="mx-5 text_invoice">
                  <CardTitle className="d-flex justify-content-between align-items-center">
                    <div>
                      {/* <span className="logo-single" /> */}
                      <img
                        src="../logos/black.svg"
                        alt="not found logo"
                        srcset=""
                        height="65px"
                      />
                    </div>
                    <div className="d-flex flex-column gap-2 ">
                      <h3
                        className="light_text_invoice"
                        style={{ alignSelf: "end", fontSize: "30px" }}
                      >
                        Invoice
                      </h3>
                      <h3 className="dark_text_invoice">
                        <strong>#220801-SLCLO-0003</strong>
                      </h3>
                    </div>
                  </CardTitle>
                  <div className="mb-2">
                    <img
                      src={Rectangle1}
                      alt="horizontal line not found"
                      srcset=""
                      width="100%"
                      className="mb-5"
                    />
                  </div>
                  <Row>
                  <Col xs="12" lg="3" className="text-left">
      <h6>Billed to</h6>
      <h6>
        <b className="dark_text_invoice">{name || "Default Name"}</b>
      </h6>
      <h6 style={{ lineHeight: "23px" }}>
        {address || "Default Address"} <br />
        {phone || "Default Phone Number"} <br />
        {taxID || "Default Tax ID"}
      </h6>
    </Col>
    <Col xs="12" lg="3" className="text-left">
                      <h6>Billed from</h6>
                      <h6>
                        <b className="dark_text_invoice">Shopilam</b>
                      </h6>
                      <h6 style={{ lineHeight: "23px" }}>
                      P3, 2nd floor, Faisal Heights, Main Boulevard, <br />
                        Faisalabad <br />
                        +92 300 8661027
                      </h6>
                    </Col>
                    <Col xs="12" lg="2"></Col>
                    <Col
                      xs="12"
                      lg="2"
                      className="text-right light_text_invoice"
                    >
                      <h6>Invoice Date</h6>
                      <h6>Due Date</h6>
                      <h6>Payment Terms</h6>
                    </Col>
                    <Col
                      xs="12"
                      lg="2"
                      className="text-right dark_text_invoice"
                    >
                      <h6>
                        <strong>10 Aug, 2022</strong>
                      </h6>
                      <h6>
                        <strong>10 Aug, 2022</strong>
                      </h6>
                      <h6>
                        <strong>7 Days</strong>
                      </h6>
                      <h6 className="light_text_invoice mb-0">
                        Invoice of (PKR)
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div style={{ float: "right" }}>
                        <h1 className="text-right fs-2 mb-5 text-color mt-n3">
                          <strong>Rs. {total.toLocaleString()}</strong>
                        </h1>
                      </div>
                    </Col>
                  </Row>



                  <Row className="py-2 px-5 mb-3" style={{ backgroundColor: "#E2E2E2" }}>
            <Col lg="1"></Col>
            <Col xs="12" lg="4">
              <h5 className="mb-0">
                <strong> Overview</strong>
              </h5>
            </Col>
            <Col xs="12" lg="3">
              <h5 className="mb-0">
                <strong>Quantity</strong>
              </h5>
            </Col>
            <Col xs="12" lg="3" className="text-right">
              <h5 className="mb-0">
                <strong>Amount</strong>
              </h5>
            </Col>
            <Col lg="1"></Col>
          </Row>
          <Row className=" px-5">
            <Col lg="1"></Col>
            <Col xs="12" lg="11">
              <h5 className="mb-1 dark_text_invoice">
                <strong>Order Fulfillment</strong>
              </h5>
            </Col>
          </Row>
          <Row className=" px-5 mb-3">
            <Col lg="1"></Col>
            <Col xs="12" lg="4">
              {billingData.length > 0 ? (
                billingData.map((store, index) => (
                  <div key={index}>
                    <p className="mb-1">{store.shopName}</p>
                  </div>
                ))
              ) : (
                <p className="mb-1">No data available</p>
              )}
            </Col>
            <Col xs="12" lg="3">
              {billingData.length > 0 ? (
                billingData.map((store, index) => (
                  <div key={index}>
                    <p className="mb-1">{store.orders.length}</p>
                  </div>
                ))
              ) : (
                <p className="mb-1">0</p>
              )}
            </Col>
            <Col xs="12" lg="3" className="text-right">
              {billingData.length > 0 ? (
                billingData.map((store, index) => {
                  const totalOrders = store.orders.length;
                  const fulfillmentCharge = store.isMaster
                    ? totalOrders * 10
                    : totalOrders * 1;
                  return (
                    <div key={index}>
                      <p className="mb-1">Rs. {fulfillmentCharge}</p>
                    </div>
                  );
                })
              ) : (
                <p className="mb-1">0</p>
              )}
            </Col>
            <Col lg="1"></Col>
          </Row>
                  <hr className="text-muted mb-3" />


                  {/* testing */}
                  <Row className="px-5">
  <Col lg="1"></Col>
  <Col xs="12" lg="4">
    <h5 className="mb-1 dark_text_invoice">
      <strong>Reselling</strong>
    </h5>
  </Col>
</Row>
{resellingData.length > 0 ? (
  resellingData.map((reseller, index) => (
    <Row className="px-5" key={index}>
      <Col lg="1"></Col>
      <Col xs="12" lg="4">
        <p className="mb-1">{reseller.userName}</p>
      </Col>
      <Col xs="12" lg="3">
        <p className="mb-1">{reseller.ordersCount}</p>
      </Col>
      <Col xs="12" lg="3" className="text-right">
        <p className="mb-1">Rs. 
          {reseller.ordersCount
            ? reseller.ordersCount * 60
            : 0}
        </p>
      </Col>
      <Col lg="1"></Col>
    </Row>
  ))
) : (
  <Row className="px-5">
    <Col lg="1"></Col>
    <Col xs="12" lg="4">
      <p className="mb-1">No reselling data found</p>
    </Col>
  </Row>
)}

                  <hr className="text-muted mb-3" />

                  {/* testing */}
                  <Row className="px-5">
  <Col lg="1"></Col>
  <Col xs="12" lg="4">
    <h5 className="mb-1 dark_text_invoice">
      <strong>Apps</strong>
    </h5>
  </Col>
  <Col xs="12" lg="3">
    <p className="mb-1">{appData.appCount}</p>
  </Col>
  <Col xs="12" lg="3" className="text-right">
    <p className="mb-1">Rs. {appData.totalCost}</p>
  </Col>
  <Col lg="1"></Col>
</Row>

                  <hr className="text-muted mb-3" />
                  {/* Testing */}
                  <Row className="px-5">
  <Col lg="1"></Col>
  <Col xs="12" lg="4">
    <h5 className="mb-1 dark_text_invoice">
      <strong>SMS</strong>
    </h5>
  </Col>
  <Col xs="12" lg="3">
    <p className="mb-1">{smsData.totalSMS || 0}</p>
  </Col>
  <Col xs="12" lg="3" className="text-right">
    <p className="mb-1">Rs. {smsData.totalCost || 0}</p>
  </Col>
  <Col lg="1"></Col>
</Row>
<hr />

          
                  <Row className="px-5">
      <Col lg="1"></Col>
      <Col xs="12" lg="4">
        <h5 className="mb-1 dark_text_invoice">
          <strong>Affiliate income</strong>
        </h5>
      </Col>
      <Col xs="12" lg="6" className="text-right">
        <p className="mb-1">-Rs. {affiliateEarnings || 0}</p>
      </Col>
      <Col lg="1"></Col>
    </Row>

                  <hr className="text-muted mb-3" /><Row className=" px-5">
        <Col lg="1"></Col>
        <Col xs="12" lg="4">
          <h5 className="mb-1 dark_text_invoice">
            <strong>Credit balance</strong>
          </h5>
        </Col>
        <Col xs="12" lg="6" className="text-right">
          <p className="mb-1">-Rs. {creditBalance}</p> {/* Dynamic value */}
        </Col>
        <Col lg="1"></Col>
      </Row>
      
                  <hr className="text-muted mb-3" />
                  <Row className=" px-5" style={{ marginBottom: "120px" }}>
                    <Col lg="1"></Col>
                    <Col xs="12" lg="4">
                      <h5 className="mb-1 dark_text_invoice">
                        <strong>Notes</strong>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris aliquam pulvinar dolor urna enim vitae vel.
                        Ultrices eget ut.
                      </p>
                    </Col>
                    <Col xs="12" lg="4" className="text-right pr-0 mb-5">
                      <h6>Sub Total</h6>
                      <h6>Upper Cap Discount</h6>
                      <h6 className="mb-0">GST(10%)</h6>
                      <hr
                        className="text-muted d-inline-block w-40"
                        style={{ marginBottom: "10px" }}
                      />
                      <div className="fs-4 dark_text_invoice">
                        <strong>Total</strong>
                      </div>
                      <hr className="text-muted d-inline-block w-40" />
                    </Col>
                    <Col xs="12" lg="2" className="text-right pl-0">
                      <h6>
      <strong>Rs. {subTotal.toLocaleString()}</strong>
    </h6>
    <h6>
      <strong>Rs. 0</strong> {/* No discount value available */}
    </h6>
    <h6>
      <strong>Rs. {gst.toLocaleString()}</strong>
    </h6>
    <hr className="text-muted" />
    <div className="fs-4 dark_text_invoice">
      <strong>Rs {total.toLocaleString()}</strong>
    </div>
    <hr className="text-muted" />
                    </Col>
                    <Col lg="1"></Col>






                    <hr className="text-muted mb-3" />

                  </Row>
                  <Col lg="1"></Col>
                  <Col xs="12" lg="4"></Col>
                    {/* Testing */}
                  
                  <Row className="mb-5 px-5">
                    <Col xs="12" lg="5">
                      <div className="mb-3 fs-5 dark_text_invoice">
                        <strong>Pay Online</strong>
                      </div>
                      <div className="text-center mb-2">
                        <div
                          className="text-light py-2 fs-5"
                          style={{
                            background: " #652F90",
                            borderRadius: "6px",
                            // minWidth: '330px',
                          }}
                        >
                          <h3 className="my-1">Pay Now</h3>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={icons8_mastercard} alt="not found" />
                        <img src={icons_visa} alt="not found" />
                        <img src={icons8_american_express} alt="not found" />
                        <img src={icons8_discover_card} alt="not found" />
                      </div>
                    </Col>
                    <Col xs="12" lg="4" className=" pl-4">
                      <div className="mb-2 fs-5 mb-3 dark_text_invoice">
                        <strong>Bank Details</strong>
                      </div>
                      <div className="fs-6 mb-2">
                        Account Holder Name: <strong>SHOPILAM</strong>
                      </div>
                      <div className="fs-6 mb-2">
                        Account #: <strong>234435646456456</strong>
                      </div>
                      <div className="fs-6 mb-2">
                        Account Type: <strong>Current</strong>
                      </div>
                      <div className="fs-6 mb-2">
                        Bank Name: <strong>AlFalah Bank Islami</strong>
                      </div>
                    </Col>
                    <Col xs="12" lg="3" className="text-right">
                      <div className="d-flex flex-column gap-3 justify-content-between align-items-center">
                        <div className="d-flex justify-content-between gap-2 align-items-center">
                          <img
                            src={image71}
                            height="30px"
                            width="120px"
                            alt="not found"
                          />
                          <img
                            src={image72}
                            height="40px"
                            width="76px"
                            alt="not found"
                          />
                        </div>
                        <div>
                          <img
                            src={image67}
                            height="120px"
                            width="120px"
                            alt="not found"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <img
                    src={Rectangle1}
                    alt="horizontal line not found"
                    srcset=""
                    width="100%"
                    className="my-5"
                  />
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-between align-items-center my-2 px-3">
                        <div className="d-flex gap-3 align-items-center ">
                          <div className="">
                            <img src={Group_fb} alt="not found" />
                          </div>
                          <div className="">
                            <img src={Group_youtube} alt="not found" />
                          </div>
                          <div className="">
                            <img src={Group_whatsApp} alt="not found" />
                          </div>
                        </div>
                        <div className="d-flex gap-5">
                          <p className="mb-0">+92 300 8661027</p>
                          <p className="mb-0">info@shopilam.com</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Cols>
          </Cols>
        </Row>
      </main>
    </>
  );
};

export default InvoiceShow;
