import axios from "axios";
import { getCookie } from "../helpers/cookie";
import { getAPIURL } from "./utils";

const url = getAPIURL();

export const getCurrentBillingData = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios.get(
    `${url}/api/billingLogs/getCurrentMonthBillingLogs`,
    config
  );
};

export const saveBillingLogs = async (storeId, current_total_price) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const data = { storeId: storeId, current_total_price: current_total_price };

  return await axios.post(`${url}/api/billingLogs/saveBilling`, data, config);
};


//billing profile
export const billing_Profile = async ( name, phone, address, taxId, notes) => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const data = { name: name, phone: phone, address: address, taxId: taxId, notes: notes };

  return await axios.post(`${url}/api/billingLogs/profile`, data, config);
};


// Order billings
export const getOrderBillings = async ({ startDateIs, endDateIs }) => {
  let token = getCookie();
  const params = new URLSearchParams();

  params.append("startDate", startDateIs);
  params.append("endDate", endDateIs);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };
  return await axios.get(`${url}/api/billingLogs/getOrderBillings`, config);
};


export const getOrderNumbers = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(`${url}/api/billingLogs/getOrderNumbers`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching order numbers:", error);
    throw error;
  }
};


//Bills generation

export const getOrderNumbersAndSalesByMonth = async () => {
  let token = getCookie();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(`${url}/api/billingLogs/getOrderNumbersAndSalesByMonth`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching order numbers:", error);
    throw error;
  }
};