import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Card, CardBody, Button, Input, Spinner } from "reactstrap";
import {
  defaultCourierCityFun,
  getUserPreferences,
  courierCityFun,
} from "../../api/courier";
import { Cols } from "../SeparatorStyle/SeparatorStyle";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
// import { rightAnimate } from "../FramerMotion/FramerMotion";

const CitiesCouriersApp = () => {
  let history = useHistory();
  const [couriersList, setCouriersList] = useState([]);
  const [search, setSearch] = useState("");
  const [dragFunction, setDragFunction] = useState(false);
  const [citiesList, setCitiesList] = useState([]);
  // const [nationCity] = useState(history.location.state);
  const [dragCities, setDragCities] = useState({});
  const [compareIndex1, setCompareIndex1] = useState([]);
  const [citiesCourierSpinner, setCitiesCourierSpinner] = useState(false);
  const [defaultCourierLabel, setDefaultCourierLabel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteCitiesFromCourier, setDeleteCitiesFromCourier] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const loadCourier = useCallback(async () => {
    try {
      const { data } = await getUserPreferences();

      setLoading(false);
      setCompareIndex1(Object.keys(data.courierCities));
      setDragCities(data.courierCities);
      setDefaultCourierLabel(Object.keys(data.defaultCitiesCourier));
      for (let value of Object.values(data.defaultCitiesCourier)) {
        setCitiesList(value);
      }
      let couriers = [];
      if (data && data?.couriers?.length > 1) {
        couriers = data?.couriers?.filter(
          (item) =>
            item.label !== Object.keys(data?.defaultCitiesCourier)?.toString()
        );
      }

      setCouriersList(couriers);
    } catch (error) {
      console.log("error found when fetch order data", error);
    }
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setLoading(true);
    setButtonDisable(true);
    loadCourier();
    handleScrollToTop();
  }, [loadCourier]);

  const handleDragstart = (e, label) => {
    e.dataTransfer.setData("label", label);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleOnDrop = (e, status, courierN) => {
    setButtonDisable(false);
    setCompareIndex1([...new Set([...compareIndex1, courierN])]);
    setDragFunction(true);
    let label = e.dataTransfer.getData("label");
    const courierName = courierN;
    let lists1 = citiesList?.filter((task) => {
      if (task === label) {
        task = status;
      }
      return task;
    });
    let lists2 = citiesList?.filter((task) => task !== label);

    setCitiesList(lists2);
    const courierAssignedCities = lists1?.filter((list) => list === label);

    if (dragCities[courierName]) {
      setDragCities({
        ...dragCities,
        [courierName]: [...dragCities[courierName], ...courierAssignedCities],
      });
    } else {
      setDragCities({
        ...dragCities,
        [courierName]: [...courierAssignedCities],
      });
    }
  };

  const handleCross = (e, label, courierName) => {
    setButtonDisable(false);
    setDragFunction(true);
    let lists2 = dragCities[courierName]?.filter((task) => task !== label);
    const deleteCities = {
      ...dragCities,
      [courierName]: [...lists2],
    };

    if (deleteCities[courierName]?.length === 0) {
      setDeleteCitiesFromCourier(true);
      setCompareIndex1(compareIndex1?.filter((value) => value !== courierName));
    }
    setDragCities(deleteCities);
    let pushing = [...citiesList];
    pushing.push(label);
    setCitiesList([...pushing]);
  };

  // executes on submit button and sets data in db of this on-boarding step
  const handleCity = async () => {
    setCitiesCourierSpinner(true);
    setButtonDisable(true);
    let temp = "";
    for (let [i, key] of Object.entries(dragCities)) {
      if (key.length === 0) {
        temp = i;
      }
    }
    let removeEmptyCities = { ...dragCities };
    delete removeEmptyCities[temp];

    let defaultCities = [];
    // extract city name and make array of un selected city as default courier cities
    for (const city of citiesList) {
      defaultCities.push(city);
    }
    if (dragFunction && dragCities) {
      if (citiesList && citiesList?.length > 0) {
        let defaultCityCourierUpdate = {
          //  nation wise courier
          [defaultCourierLabel && defaultCourierLabel]:
            defaultCities && defaultCities,
        };
        await defaultCourierCityFun(defaultCityCourierUpdate);
      }
      await courierCityFun(removeEmptyCities);
      setCitiesCourierSpinner(false);
      deleteCitiesFromCourier
        ? toast.success("Courier cities updated successfull!")
        : toast.success("Courier cities updated successfully!");
    } else {
      let defaultCityCourierUpdate = {
        [defaultCourierLabel && defaultCourierLabel]: citiesList && citiesList,
      };
      await defaultCourierCityFun(defaultCityCourierUpdate);
    }
  };

  return (
    <main>
      <motion.div

      // variants={rightAnimate} initial="hidden" animate="visible"
      >
        {loading ? (
          <Row>
            {new Array(4).fill(0).map((item, i) => (
              <Cols xxs="12" sm="6" lg="3" md="4" key={i + 1}>
                <Card
                  className="w-100"
                  style={{ height: "700px", padding: "12px" }}
                >
                  <div className="text-center">
                    <div className="skeleton courier-image-skeleton"></div>
                  </div>
                  {new Array(17).fill(0).map((item, i) => (
                    <div
                      className="skeleton skeleton-text mb-2"
                      key={i + 1}
                    ></div>
                  ))}
                </Card>
              </Cols>
            ))}
          </Row>
        ) : (
          <Row>
            <Cols xxs="12" sm="6" lg="3" md="4">
              <Card style={{ height: "700px" }}>
                <div className="d-flex justify-content-center">
                  {defaultCourierLabel &&
                  defaultCourierLabel?.includes("PostEx") ? (
                    <img
                      src="./img/companiesLogo/postEx.png"
                      alt="postEx"
                      height="100px"
                    />
                  ) : defaultCourierLabel &&
                    defaultCourierLabel?.includes("Leopard") ? (
                    <img
                      src="./img/companiesLogo/LCS.png"
                      alt="leopard"
                      height="100px"
                    />
                  ): defaultCourierLabel &&
                  defaultCourierLabel?.includes("HBC") ? (
                  <img
                    src="./img/companiesLogo/HBC_logo.png"
                    alt="HBC"
                    height="100px"
                  />
                ) : defaultCourierLabel &&
                    defaultCourierLabel?.includes("Swyft") ? (
                    <img
                      src="./img/companiesLogo/swyft.png"
                      alt="swyft"
                      height="100px"
                    />
                  ) : defaultCourierLabel &&
                    defaultCourierLabel?.includes("M&P") ? (
                    <img
                      src="./img/companiesLogo/MP-Logo.png"
                      alt="m&p"
                      height="100px"
                    />
                  ) : defaultCourierLabel &&
                    defaultCourierLabel?.includes("Trax") ? (
                    <img
                      src="./img/companiesLogo/Trax.png"
                      alt="Trax"
                      height="100px"
                    />
                  ) : (
                    defaultCourierLabel &&
                    defaultCourierLabel?.includes("TPL") && (
                      <img
                        src="./img/companiesLogo/Rider.png"
                        alt="tpl"
                        height="100px"
                      />
                    )
                  )}
                </div>
                <div className="px-3">
                  <div className="search-sm d-inline-block pb-4 align-top d-flex justify-content-center">
                    <Input
                      type="text"
                      name="keyword"
                      id="search"
                      placeholder="Search City"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      style={{
                        padding: "7px",
                        fontSize: "16px",
                      }}
                    />
                  </div>
                </div>
                <CardBody
                  className="pt-0 customScroll"
                  style={{ overflow: "auto", padding: "20px" }}
                >
                  <div>
                    {search === "" ? (
                      citiesList &&
                      citiesList?.map((city, index) => (
                        <Card
                          key={index + 1}
                          className="mb-2"
                          draggable
                          onDragStart={(e) => {
                            handleDragstart(e, city);
                          }}
                        >
                          <CardBody id={index}>
                            <div className="d-flex align-items-center">
                              <img
                                src="./img/location/Location.png"
                                alt="not found"
                              />
                              <p
                                className="ms-3 mb-0"
                                style={{ color: "#909090" }}
                              >
                                {city}
                              </p>
                            </div>
                          </CardBody>
                        </Card>
                      ))
                    ) : (
                      <>
                        {citiesList &&
                          citiesList
                            ?.filter((city) =>
                              city.toLowerCase().includes(search.toLowerCase())
                            )
                            .map((city, index) => (
                              <Card
                                key={index + 1}
                                className="mb-2"
                                draggable
                                onDragStart={(e) => {
                                  handleDragstart(e, city);
                                }}
                              >
                                <CardBody className="" id={index}>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="./img/location/Location.png"
                                      alt="not found"
                                    />
                                    <p
                                      className="ms-3 mb-0"
                                      style={{ color: "#909090" }}
                                    >
                                      {city}
                                    </p>
                                  </div>
                                </CardBody>
                              </Card>
                            ))}
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Cols>
            <Cols xxs="12" sm="6" lg="9" md="8" className="px-2 cities-courier">
              <Row className="d-flex customScroll flex-nowrap overflow-auto">
                {couriersList && couriersList.length >= 0 ? (
                  <>
                    {couriersList?.map((courier, index) => (
                      <Cols
                        xxs="6"
                        md="6"
                        sm="10"
                        lg="4"
                        className="px-1"
                        key={`${courier.label}_tooltip`}
                      >
                        <Card
                          style={{ height: "700px" }}
                          id={courier.label}
                          onDragOver={(e) => handleDragOver(e)}
                          onDrop={(e) =>
                            handleOnDrop(e, "courier", courier.label, index)
                          }
                        >
                          <div className="d-flex justify-content-center">
                            {courier.label === "PostEx" ? (
                              <img
                                src="./img/companiesLogo/postEx.png"
                                alt="postEx"
                                height="100px"
                              />
                            ) : courier.label === "Leopard" ? (
                              <img
                                src="./img/companiesLogo/LCS.png"
                                alt="leopard"
                                height="100px"
                              />
                            ) : courier.label === "Swyft" ? (
                              <img
                                src="./img/companiesLogo/swyft.png"
                                alt="swyft"
                                height="100px"
                              />
                            ) : courier.label === "M&P" ? (
                              <img
                                src="./img/companiesLogo/MP-Logo.png"
                                alt="m&p"
                                height="100px"
                              />
                            ) : courier.label === "Trax" ? (
                              <img
                                src="./img/companiesLogo/Trax.png"
                                alt="trax"
                                height="100px"
                              />
                            )
                            : courier.label === "HBC" ? (
                              <img
                                src="./img/companiesLogo/HBC_logo.png"
                                alt="HBC"
                                height="100px"
                              />
                            )  : (
                              courier.label === "TPL" && (
                                <img
                                  src="./img/companiesLogo/Rider.png"
                                  alt="tpl"
                                  height="100px"
                                />
                              )
                            )}
                          </div>
                          {compareIndex1.includes(courier.label) ? null : (
                            <h4 className={"custom-placeholder"}>
                              Drag Here....
                            </h4>
                          )}

                          <div
                            style={{
                              padding: "12px",
                              overflow: "auto",
                            }}
                            className="customScroll"
                          >
                            {Object.keys(dragCities).length > 0 &&
                              dragCities[courier.label] &&
                              dragCities[courier.label]?.map((city, index) => (
                                <Card
                                  key={index + 1}
                                  className="mb-2"
                                  draggable
                                >
                                  <CardBody id={index}>
                                    <div className="d-flex justify-content-between ">
                                      <div className="d-flex align-items-center">
                                        <img
                                          src="./img/location/Location.png"
                                          alt="not found"
                                        />
                                        <p
                                          className="ms-3 mb-0"
                                          style={{ color: "#909090" }}
                                        >
                                          {city}
                                        </p>
                                      </div>
                                      <div>
                                        <div
                                          className="pointer text-danger"
                                          onClick={(e) =>
                                            handleCross(e, city, courier.label)
                                          }
                                        >
                                          x
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              ))}
                          </div>
                        </Card>
                      </Cols>
                    ))}
                  </>
                ) : (
                  <div className="loading"></div>
                )}
              </Row>
            </Cols>
          </Row>
        )}
        <footer className="float-right">
          {loading ? (
            <>
              <div className="skeleton skeleton-button py-2 my-4 me-2"></div>
              <div className="skeleton skeleton-button px-5 py-2 my-4"></div>
            </>
          ) : (
            <>
              <Button
                size="sm"
                className="btn btn-secondary py-2 my-4 me-2"
                onClick={() => history.push("/admin/myCouriers")}
              >
                Go Back
              </Button>
              <Button
                type="button"
                outline
                color="primary"
                size="lg"
                className="py-2 my-4"
                disabled={buttonDisable}
                style={citiesCourierSpinner ? { padding: "10px 69px" } : null}
                onClick={handleCity}
              >
                {citiesCourierSpinner ? (
                  <Spinner size={"sm"}>Loading...</Spinner>
                ) : (
                  "Update"
                )}
              </Button>
            </>
          )}
        </footer>
      </motion.div>
    </main>
  );
};

export default CitiesCouriersApp;
