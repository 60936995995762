import React, { useState, useEffect } from "react";
import Popover from "./popover";
import DataTable from './../tables/table';
import {
    getCouriersLoadsheet,
    getOrderByName
} from "../../api/orders"; 
import { handleDownloadPdf } from "../../helpers/downloadMergedPDF"; 
const ScanOrdersPopover = ({ onClose }) => {
    const [scannedOrders, setScannedOrders] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [barcodeDisplay, setBarcodeDisplay] = useState('');
    const [paginationLoading, setPaginationLoading] = useState(false);
    const [completedPDF, setCompletedPDF] = useState({ completed: 0 });

    const columns = [
        { Header: "Serial Number", accessor: "serialNumber" },
        { Header: "Order Reference", accessor: "orderReference" }
    ];

    const currentData = scannedOrders.slice(pageIndex * 5, (pageIndex + 1) * 5);

    // Commented out the barcode scanning logic
    const handleScan = async (barcodeString) => {
        if (barcodeString==="") {
            console.log("Empty barcode string, skipping scan.");
            return;
        }
    
        try {
            const { data: orderDetails } = await getOrderByName(barcodeString);
            if (orderDetails) {
                const newOrder = {
                    serialNumber: scannedOrders.length + 1,
                    orderReference: barcodeString,
                    ...orderDetails
                };
                setScannedOrders([...scannedOrders, newOrder]);
                setBarcodeDisplay(barcodeString);
            }
        } catch (error) {
            console.error("Error fetching order details:", error);
        }
    };
    
     useEffect(() => {
        let barcodeScan = "";

        const handleKeyDown = (e) => {
            if (e.keyCode === 13 && barcodeScan.length > 3) {
                handleScan(barcodeScan);
                barcodeScan = "";
                return;
            }
            if (e.keyCode === 16) {
                return;
            }
            barcodeScan += e.key;
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [scannedOrders]);


    const [couriersLoadSheetData, setCouriersLoadSheetData] = useState([]);

    const handleDownloadLoadSheets = async () => {
        setPaginationLoading(true);
        const orderReferences = scannedOrders.map((o) => o.orderReference);

        try {
            const { orders } = await getOrderByName(orderReferences);
            console.log("Fetched Orders:", orders);

            if (orders && orders.length > 0) {
                const orderIds = orders.map((order) => order._id); 
                const { data } = await getCouriersLoadsheet({ orderIds });
                console.log("Courier Loadsheet Data:", data);
                
                setCouriersLoadSheetData(data);
                await handleLoadSheetPDF();
            } else {
                console.warn("No orders found for the provided references.");
            }
        } catch (error) {
            console.error("Error fetching orders or couriers' load sheets:", error);
        }

        setPaginationLoading(false);
    };

    const handleLoadSheetPDF = async () => {
        try {
            for (let courier of couriersLoadSheetData) {
                if (!courier.orders || courier.orders.length === 0) {
                    console.warn(`No orders found for courier: ${courier.courier}`);
                    continue;
                }

                const {
                    data: { base64PDF },
                } = await getCouriersLoadsheet({
                    courier: courier.courier,
                    orderIds: courier.orders.map((o) => o?._id),
                });

                if (base64PDF) {
                    await handleDownloadPdf(base64PDF, courier.orders);
                    setCompletedPDF((prevState) => ({
                        ...prevState,
                        completed: prevState.completed + 1,
                    }));
                } else {
                    console.warn(`Invalid or missing base64PDF for courier: ${courier.courier}`);
                }
            }
        } catch (error) {
            console.error("Error generating PDF:", error);
        } finally {
            setCouriersLoadSheetData([]);
            setPaginationLoading(false);
        }
    };

    return (
        <Popover onClose={onClose}>
            <h2>Scan Orders</h2>
            <div>
                <DataTable
                    columns={columns}
                    data={currentData}
                    pagination={true}
                    defaultPageSize={5}
                    fetchData={(options) => setPageIndex(options.page)}
                />
                <button onClick={handleDownloadLoadSheets} className="btn btn-primary custom-btn">
                    Create Load Sheet
                </button>
            </div>
        </Popover>
    );
};

export default ScanOrdersPopover;
