import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import { getCurrentBillingData } from "../../../api/billing";
import { Row, Card, CardBody, CardTitle, Form, Col } from "reactstrap";
import { Cols } from "../../SeparatorStyle/SeparatorStyle";
import Mastercard_Logo from "../../../images/Billing/Mastercard_Logo.png";

// Getting Apis
import { getOrderBillings, getOrderNumbers } from "../../../api/billing";
import { getAllResellers } from "../../../api/reselling"; // Update with the actual path to your API functions

//SMS for future use
// import { getSMSData } from "./yourApiFile"; // Update with the actual path to your API functions

const BillingCycleShow = () => {
  // const history = useHistory();
  // const [bills, setBills] = useState([]);
  // const [toggleSubscription, setToggleSubscription] = useState(false);
  // const [usd, setUSD] = useState([]);
  // const [percent, setPercent] = useState([]);

  let today = new Date();
  let date =
    today.getDate() +
    " " +
    today.toLocaleString("en-us", { month: "short" }) +
    " " +
    today.getFullYear();

  //Fetching Data
  const [billingData, setBillingData] = useState([]);
  const [totalFulfillmentCharge, setTotalFulfillmentCharge] = useState(0);
  const [resellingData, setResellingData] = useState([]);
  const [totalResellingCharge, setTotalResellingCharge] = useState(0);

  const [orderNumbers, setOrderNumbers] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  // SMS for future use
  const [smsData, setSMSData] = useState({
    totalSMS: 0,
    smsUsed: 0,
    totalCost: 0,
  });

  // APP for future use
  const [appData, setAppData] = useState({
    appCount: 0, // Default to zero items
    appName: "No Apps Installed", // Placeholder name
    totalCost: 0, // Placeholder cost
    reportCost: 0, // Placeholder report cost
    message: "There Is No App On Your Account Right Now", // Placeholder message
  });
  useEffect(() => {
    // Future API call to fetch app data can be placed here
    // setAppData({ appCount: fetchedData.count, appName: fetchedData.name, ...});
  }, []);

  // Void Order for future use
  const [voidOrders, setVoidOrders] = useState(0);
  const [voidOrdersAmount, setVoidOrdersAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [affiliateEarnings, setAffiliateEarnings] = useState(0);
  const [affiliateStores, setAffiliateStores] = useState([]);
  const [grossTotal, setGrossTotal] = useState(0);
  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const res = await fetch("/api/financial-summary");
        const data = await res.json();

        // Update the state with API data
        setVoidOrders(data.voidOrders || 0);
        setVoidOrdersAmount(data.voidOrdersAmount || 0);
        setDiscount(data.discount || 0);
        setAffiliateEarnings(data.affiliateEarnings || 0);
        setAffiliateStores(data.affiliateStores || []);
        setGrossTotal(data.grossTotal || 0);
      } catch (error) {
        console.error("Error fetching financial summary:", error);
      }
    };

    fetchData();
  }, []);

  // Set startDateIs and endDateIs to August 2023
  const [startDateIs, setStartDateIs] = useState("2023-08-01");
  const [endDateIs, setEndDateIs] = useState("2023-08-31");

  // Fetch billing data
  // useEffect(() => {
  //   const fetchBillingData = async () => {
  //     try {
  //       const response = await getOrderBillings({ startDateIs, endDateIs });
  //       const data = response.data.separatStoresData;
  //       setBillingData(data);

  //       // Calculate total fulfillment charges
  //       const totalCharge = data.reduce((acc, store) => {
  //         const totalOrders = store.orders.length;
  //         const fulfillmentCharge = store.isMaster ? totalOrders * 10 : totalOrders * 1;
  //         return acc + fulfillmentCharge;
  //       }, 0);

  //       setTotalFulfillmentCharge(totalCharge);
  //     } catch (error) {
  //       console.error("Error fetching billing data:", error);
  //     }
  //   };

  //   fetchBillingData();
  // }, [startDateIs, endDateIs]);
  useEffect(() => {
    const fetchBillingData = async () => {
      try {
        const response = await getOrderBillings({ startDateIs, endDateIs });
        const data = response.data.separatStoresData;
        setBillingData(data);

        // Calculate total fulfillment charges
        const totalCharge = data.reduce((acc, store) => {
          const totalOrders = store.orders.length;
          const fulfillmentCharge = store.isMaster
            ? totalOrders * 10
            : totalOrders * 1;
          return acc + fulfillmentCharge;
        }, 0);

        setTotalFulfillmentCharge(totalCharge);

        // // Calculate total amount
        // const grossTotal = data.reduce((acc, store) => {
        //   return acc + store.orders.reduce((orderTotal, order) => orderTotal + parseFloat(order.total_price), 0);
        // }, 0);

        // setTotalAmount(grossTotal);
      } catch (error) {
        console.error("Error fetching billing data:", error);
      }
    };

    fetchBillingData();
  }, [startDateIs, endDateIs]);

  // Fetch order numbers
  useEffect(() => {
    const fetchOrderNumbers = async () => {
      try {
        const response = await getOrderNumbers();
        setOrderNumbers(response.separatStoresData);
      } catch (error) {
        console.error("Error fetching order numbers:", error);
      }
    };

    fetchOrderNumbers();
  }, []);

  useEffect(() => {
    const fetchResellingData = async () => {
      try {
        const response = await getAllResellers();
        const resellers = response.data.seller.resellers; // Adjust based on the actual response structure

        // Calculate total reselling charges
        let totalOrders = 0;
        let updatedResellers = [];

        if (resellers.length > 0) {
          updatedResellers = resellers.map((reseller) => {
            const ordersCount = reseller.ordersCount || 0; // Adjust according to how you get the number of orders
            totalOrders += ordersCount;
            return {
              ...reseller,
              ordersCount,
            };
          });
        }

        const totalCharge = totalOrders * 60; // Assuming Rs. 60 per order

        setResellingData(updatedResellers);
        setTotalResellingCharge(totalCharge);
      } catch (error) {
        console.error("Error fetching reselling data:", error);
      }
    };

    fetchResellingData();
  }, []);

  // SMS DATA
  // useEffect(() => {
  //   const fetchSMSData = async () => {
  //     try {
  //       const response = await getSMSData();
  //       // Update the smsData based on the response
  //       const { totalSMS, smsUsed, totalCost } = response.data; // Adjust based on the actual response structure
  //       setSMSData({ totalSMS, smsUsed, totalCost });
  //     } catch (error) {
  //       console.error("Error fetching SMS data:", error);
  //     }
  //   };

  //Load Bill at First Render
  // const loadBill = useCallback(async () => {
  //   try {
  //     const billData = await getCurrentBillingData();
  //     setBills(billData.data);
  //     setUSD(billData.data.filter((data) => data.multiplier === '0.1 USD'));
  //     setPercent(billData.data.filter((data) => data.multiplier === '0.5%'));
  //   } catch (error) {
  //     console.log('error found when fetch order data', error);
  //   }
  // }, []);

  // useEffect(() => {
  //   loadBill();
  // }, [loadBill]);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <main>
        <div className="ml-3 mb-2">
          <Link
            to={`/admin/payments/billing`}
            onClick={() => {
              handleScrollToTop();
            }}
          >
            <button
              className="material-icons-outlined mr-3"
              style={{
                border: "2px solid gray",
                padding: "3px",
                display: "inline",
              }}
            >
              ←
            </button>
          </Link>
          <h2 className="mb-4" style={{ display: "inline" }}>
            <b>Billing Cycle: {startDateIs}</b>
          </h2>
        </div>
        <br />
        <Form>
          <Row>
            <Cols xxs="12" className="row icon-cards-row mb-2">
              <Cols xs="12" sm="12" md="7" lg="8" className="mb-4 ">
                <Card className="mb-2">
                  <CardBody>
                    <CardTitle className="mx-3">
                      <h5>
                        <b>Order Fulfillment</b>
                        <b
                          className="mr-0"
                          style={{ marginRight: "5px", float: "right" }}
                        >
                          Rs. {totalFulfillmentCharge}
                        </b>
                      </h5>
                    </CardTitle>
                    <hr className="text-muted" />
                    <br />
                    <div className="mx-3">
                      {billingData.length > 0 ? (
                        billingData.map((store, index) => {
                          const totalOrders = store.orders.length;
                          const fulfillmentCharge = store.isMaster
                            ? totalOrders * 10
                            : totalOrders * 1;

                          return (
                            <Row key={index}>
                              <Col md="6">
                                <div>
                                  <h6 className="mr-5">
                                    {store.shopName}
                                    <strong> ({totalOrders} orders)</strong>
                                  </h6>
                                </div>
                              </Col>
                              <Col md="2" className="text-center">
                                <p>(x {store.isMaster ? 10 : 1})</p>
                              </Col>
                              <Col md="4" className="text-right">
                                <p>Rs. {fulfillmentCharge}</p>
                              </Col>
                            </Row>
                          );
                        })
                      ) : (
                        <p>No billing data found</p>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <Card className="mb-2">
                  <CardBody>
                    <CardTitle className="mx-3">
                      <h5>
                        <b>Reselling Orders</b>
                        <b
                          className="mr-0"
                          style={{ marginRight: "5px", float: "right" }}
                        >
                          Rs. {totalResellingCharge}
                        </b>
                      </h5>
                    </CardTitle>
                    <hr className="text-muted" />
                    <br />
                    <div className="mx-3">
                      {resellingData.length > 0 ? (
                        resellingData.map((reseller, index) => (
                          <Row key={index}>
                            <Col md="6">
                              <div>
                                <h6 className="mr-5">
                                  {reseller.userName}
                                  <p>
                                    <strong>
                                      {" "}
                                      ({reseller.ordersCount || 0} orders)
                                    </strong>
                                  </p>
                                </h6>
                              </div>
                            </Col>
                            <Col md="2" className="text-center">
                              <p>(x 60)</p>{" "}
                              {/* Assuming 60 orders per reseller */}
                            </Col>
                            <Col md="4" className="text-right">
                              <p>
                                Rs.{" "}
                                {reseller.ordersCount
                                  ? reseller.ordersCount * 60
                                  : 0}
                              </p>{" "}
                              {/* Rs. 60 per order */}
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <p>No reselling data found</p>
                      )}
                    </div>
                  </CardBody>
                </Card>

                <Card className="mb-2">
                  <CardBody>
                    <CardTitle className="mx-3 mb-0">
                      <h5>
                        <b>SMS</b>
                        <b
                          className="mr-0"
                          style={{ marginRight: "5px", float: "right" }}
                        >
                          Rs. {smsData.totalCost || 0}{" "}
                          {/* Default to Rs. 0 if no data */}
                        </b>
                      </h5>
                      <hr className="text-muted" />
                      <Row>
                        <Col md="8">
                          <div>
                            <p className="mb-2">
                              <strong>{smsData.totalSMS || 0} SMS</strong>
                            </p>
                            <p>
                              {smsData.smsUsed > 0
                                ? `You have ${smsData.smsUsed} SMS used out of ${smsData.totalSMS} available.`
                                : "There are no messages on your account right now."}
                            </p>
                          </div>
                        </Col>
                        <Col md="4" className="text-right">
                          <p>Rs. {smsData.totalCost || 0}</p>{" "}
                          {/* Default to Rs. 0 if no data */}
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardBody>
                </Card>

                <Card className="mb-2">
                  <CardBody>
                    <CardTitle className="mx-3 mb-0">
                      <h5>
                        <b>
                          Apps ({appData.appCount} item
                          {appData.appCount !== 1 && "s"})
                        </b>
                        <b
                          className="mr-0"
                          style={{ marginRight: "5px", float: "right" }}
                        >
                          Rs. {appData.totalCost}
                        </b>
                      </h5>
                      <hr className="text-muted" />
                      <Row>
                        <Col md="8">
                          <div>
                            <p className="mb-2">
                              <strong>{appData.appName}</strong>
                            </p>
                            <p>{appData.message}</p>
                          </div>
                        </Col>
                        <Col md="4" className="text-right">
                          <p>Rs. {appData.reportCost}</p>
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardBody>
                </Card>

                <Card className="mb-2">
                  <CardBody>
                    <CardTitle className="mx-3 mb-0">
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">
                              <strong>
                                Void orders{" "}
                                <span className="small">
                                  (Return, Void, Cancelled, Manual, Refund
                                  orders)
                                </span>
                              </strong>
                            </h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>
                            <strong>-Rs. {voidOrdersAmount}</strong>
                          </h6>
                        </Col>
                      </Row>
                      <hr className="text-muted" />
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">
                              <strong>
                                Freetier discount/Max out discount
                              </strong>
                            </h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>
                            <strong>-Rs. {discount}</strong>
                          </h6>
                        </Col>
                      </Row>
                      <hr className="text-muted" />
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">
                              <strong>Affiliate Earnings</strong>
                            </h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>
                            <strong>-Rs. {affiliateEarnings}</strong>
                          </h6>
                        </Col>
                      </Row>
                      {affiliateStores.map((store, index) => (
                        <Row key={index}>
                          <Col md="9" className="text-right">
                            <div>
                              <h6 className="mb-2">{store.name}</h6>
                            </div>
                          </Col>
                          <Col md="3" className="text-right">
                            <h6>-Rs. {store.amount}</h6>
                          </Col>
                        </Row>
                      ))}
                    </CardTitle>
                  </CardBody>
                </Card>

                <Card className="mb-2">
                  <CardBody className="mx-3">
                    <CardTitle className="mb-0">
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">
                              <strong>
                                <u>Billing Summary</u>
                              </strong>
                            </h6>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">Discount (First month)</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>-Rs. {discount}</h6>
                        </Col>
                      </Row>
                      <Row></Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">Order Fulfillment Charges</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>Rs. {totalFulfillmentCharge}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">Affiliate Earnings Charges</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>Rs. {affiliateEarnings}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">SMS Charges</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>Rs. {smsData.totalCost}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">App Charges</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>Rs. {appData.totalCost}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">Void Order Charges</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>Rs. {voidOrdersAmount}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">
                              Freetier discount/Max out discount
                            </h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>-Rs. {discount}</h6>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="9" className="text-right">
                          <div>
                            <h6 className="mb-2">Tax</h6>
                          </div>
                        </Col>
                        <Col md="3" className="text-right">
                          <h6>0%</h6>
                        </Col>
                      </Row>
                    </CardTitle>
                    <hr className="text-muted" />
                    <Row>
                      <Col md="9" className="text-right">
                        <div>
                          <h3 className="mb-2">
                            <strong>Total</strong>
                          </h3>
                        </div>
                      </Col>
                      <Col md="3" className="text-right">
                        <h3>
                          <strong>
                            Rs.{" "}
                            {totalAmount +
                              totalFulfillmentCharge +
                              affiliateEarnings -
                              discount}
                          </strong>
                        </h3>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <div
                  className="mx-3 mt-5 px-3 py-2"
                  style={{ backgroundColor: "#EDEDED" }}
                >
                  <Row>
                    <Col>
                      <h5 className="mb-5">
                        <b>Understanding your Bill</b>
                      </h5>
                    </Col>
                  </Row>
                  <hr className="text-muted" />
                  <Row className="mt-4">
                    <Col>
                      <h5 className="mb-5">
                        <b>Order Fulfillment</b>
                      </h5>
                      <p>
                        Order fulfillment refers to the process of receiving,
                        processing, and delivering orders to customers. This
                        includes inventory management, packaging, and shipping.
                        Efficient order fulfillment ensures timely delivery and
                        customer satisfaction.
                      </p>
                    </Col>
                    <Col>
                      <h5 className="mb-5">
                        <b>Muiltistore Management</b>
                      </h5>
                      <p>
                        Multistore management involves overseeing multiple store
                        locations or sales channels from a centralized system.
                        This includes tracking sales, inventory, and managing
                        staff across different locations to ensure consistency
                        and efficiency.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5 className="mb-5">
                        <b>Reselling</b>
                      </h5>
                      <p>
                        Reselling refers to the process of purchasing products
                        and selling them again, often at a higher price. This
                        can involve sourcing items from wholesalers or
                        manufacturers and selling them to end customers or
                        through various sales platforms.
                      </p>
                    </Col>
                    <Col>
                      <h5 className="mb-5">
                        <b>SMS</b>
                      </h5>
                      <p>
                        SMS (Short Message Service) is a text messaging service
                        used for sending notifications, updates, and alerts to
                        customers. It is an effective way to communicate
                        important information quickly and efficiently.
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <h5 className="mb-5">
                        <b>Apps</b>
                      </h5>
                      <p>
                        Apps refer to software applications designed to perform
                        specific tasks or provide services on mobile devices or
                        computers. They can include features such as order
                        tracking, inventory management, and customer support.
                      </p>
                    </Col>
                    <Col>
                      <h5 className="mb-5">
                        <b>Void Orderst</b>
                      </h5>
                      <p>
                        Void orders are transactions that have been canceled or
                        rejected before they are processed or completed. This
                        can occur due to various reasons such as payment issues
                        or customer requests.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <h6>
                        Email <Link to=" ">billing@shopilam.com</Link> if you
                        have any questions about your{" "}
                        <Link to=" ">Shopilam</Link> bill
                      </h6>
                    </Col>
                  </Row>
                </div>
              </Cols>
              <Cols xs="12" sm="12" md="5" lg="4" className="mb-4 ">
                <Card>
                  <CardBody className="pb-2">
                    <CardTitle className="mx-3 text-center">
                      <h2 className="text-color mb-4">
                        <b>
                          Rs.{" "}
                          {totalAmount +
                            totalFulfillmentCharge +
                            affiliateEarnings -
                            discount}
                        </b>
                      </h2>
                    </CardTitle>
                    <p
                      className="mb-3 text-light text-center py-2"
                      style={{
                        backgroundColor: "#b69329",
                        borderColor: "#b69329",
                        fontSize: "16px",
                      }}
                    >
                      <strong>Pay Now</strong>
                    </p>

                    <div className="mx-3">
                      <div className="mb-2">
                        <h6>
                          <b>PAYMENT METHOD</b>
                        </h6>
                      </div>
                      <div className="d-flex justify-content-center align-items-center gap-1 mb-4">
                        <div className="border">
                          <img
                            src={Mastercard_Logo}
                            height="25"
                            weight="35"
                            alt="Mastercard Logo"
                          />
                        </div>
                        <p className="mb-0">
                          <b>Bank Transfer</b>
                        </p>
                      </div>
                      <div>
                        <h6 className="mb-2">
                          <strong>BANK NAME</strong>
                        </h6>
                        <p>AlFalah Bank Limited</p>
                      </div>
                      <hr className="text-muted" />
                      <div>
                        <h6 className="mb-3">
                          <b>PAYMENT TIMELINE</b>
                        </h6>
                        <p className="mb-1">Issue Date</p>
                        <p>
                          <strong>{startDateIs}</strong>
                        </p>
                        <p className="mb-1">Due Date</p>
                        <p>
                          <strong>{endDateIs}</strong>
                        </p>
                        {/* <p className="mb-1">Paid Date</p>
        <p>
          <strong>{date}</strong>
        </p> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Cols>
            </Cols>
          </Row>
        </Form>
      </main>
    </>
  );
};

export default BillingCycleShow;
